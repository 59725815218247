import React, { useRef, useEffect, useState } from "react";
import Sidemenu from '../components/Sidemenu';
import { Snackbar, Grid, TextField, Button } from "@mui/material";
import Pagination from "../components/pagination";
import HttpService from "../services/HttpService";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { FORMID, GRADIENTIMAGECOLOR, MID_URL_FORM_RUNTIME_DATA } from "../utils/constants";
import './conflicts/conflicts.css'
import { VERSION } from "../constants";
import KeycloakService from "../services/KeycloakService";
import editIcon from '../assets/images/edit.svg';

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import Paper from '@mui/material/Paper';

import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import { docsData, paymentStatusSubmitPayload, paymentDetailsPayload } from "../types/Module_Type";

const _axios = HttpService.getAxiosClient();
const userRoleVal = localStorage.getItem("userRole") as string;

const useStyles = makeStyles(() => ({
  snackbarStyleViaContentProps: {
    backgroundImage: GRADIENTIMAGECOLOR,
  },
}));

const tabsName = ['Payments Approved', 'Processed Payments'];
const tableHead = ['Patient UHID', 'Referrer Name', 'Referrer Mobile', 'Patient Name', 'Date of Discharge', 'Referrer Amount', 'Finalised Amount', 'Status'];

const PaymentDetailsF: React.FC = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [activeTab, setActiveTab] = React.useState(0);
  const [activeTabName, setActiveTabName] = React.useState('payments set to be approved');
  const [docArray, setDocArray] = useState<docsData[]>([]);
  const [page, setPage] = React.useState(1);
  const [rowPageSize, setRowPageSize] = React.useState(10);
  const [totalPages, setTotalPages] = React.useState(Number);
  const [offsetVal, setOffsetVal] = React.useState(0);

  const [openDialog, setOpenDialog] = React.useState(false);
  const [paymentStatus, setPaymentStatus] = React.useState('');
  const [selPatient, setSelPatient] = React.useState('');
  const [selPatientVisitId, setSelPatientVisitId] = React.useState('');
  const [reasonValue, setReasonValue] = useState<string>('');
  const [paymentRejectedOptions, setPaymentRejectedOptions] = useState(false);

  const PaymentsApprovedTableContainerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    getInitialPaymentDetails(activeTab);
  }, []);

  useEffect(() => {
    const resizeHandler = () => {
      const container = PaymentsApprovedTableContainerRef.current
      if (container) {
        const availableHeight: any =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 90}px`
      }
    }

    window.addEventListener('resize', resizeHandler)
    resizeHandler()

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    boxShadow: 'none'
  }));

  const previousPage = () => {
    let previousPageNo = page - 1;
    setPage(previousPageNo);
    setOffsetVal(offsetVal - rowPageSize);
  };
  const nextPage = () => {
    let nextPageNo = page + 1;
    setPage(nextPageNo);
    setOffsetVal(offsetVal + rowPageSize);
  };
  const rowPerPageChange = (e: any) => {
    let val = e.target.value;
    setRowPageSize(val);
  };

  const commonParams = {
    limit: rowPageSize,
    offset: offsetVal,
  };

  function generatePaymentDetailsPayload<T extends { [key: string]: string | number | Date }>(
    intent: string,
    params: T
  ): paymentDetailsPayload {
    return {
      intent,
      state: {
        ...commonParams,
        ...params,
      },
    };
  }

  const handleTabClick = async (index: number) => {
      if(tabsName[index].toLowerCase() != activeTabName){
        setActiveTab(index);
        setDocArray([]);
        getInitialPaymentDetails(index);
    }
  }

  const getInitialPaymentDetails = async (index: number) => {
    
    try {
      let paymentDetailsPayload: paymentDetailsPayload = {
        intent: "getPaymentDetailsForSalesHead",
        state: {
          ...commonParams,
          financeId: KeycloakService.getSub(),
        },
      };

      let params: { [key: string]: string | number | Date } = {};
      
      let intent = 'getPaymentDetailsForSalesHead'

      if(index == 0){
        /* Payments approved */
        params = {
          ...commonParams,
          managerApproval: "approved",
          salesHeadApproval: "approved",
          transactionStatus: "assigned",
        }
      } else if(index == 1){
        /* Processed Payments */
        params = {
          ...commonParams,
          managerApproval: "approved",
          salesHeadApproval: "approved",
          transactionStatus: "completed",
        }
      }

      paymentDetailsPayload = generatePaymentDetailsPayload(intent, params);

      const paymentDetailsResp = await _axios.post(
          `${process.env.REACT_APP_MAIN_SERVER_URL}${process.env.REACT_APP_PROJECT_ID}`,
          paymentDetailsPayload,
          {
              headers: {
                  version: VERSION,
          },
          }
      );

        if (paymentDetailsResp.status === 200) {
            const paymentDetailsRespData = paymentDetailsResp.data;

            if (
                paymentDetailsRespData &&
                paymentDetailsRespData.trade_partner_payment_details.length != 0
            ) {
                setDocArray(paymentDetailsRespData.trade_partner_payment_details.sort((a: { discharged_on: number; }, b: { discharged_on: number; }) => a.discharged_on - b.discharged_on));
                setTotalPages(
                Math.ceil(paymentDetailsRespData.trade_partner_payment_details_aggregate.aggregate
                .count / rowPageSize)
                );
                setOffsetVal((rowPageSize * page) - rowPageSize);
            } else {
                setDocArray([]);
                setTotalPages(1);
            }
        }
    } catch (error) {
      console.error(error);
    }
  }  
      

  const setDateFormat = (date: String) => {
      if(date != null && date != ""){
          let dateWithOutString = date.replace(/"/gi, "");
          dateWithOutString = moment(dateWithOutString).format('DD-MMM-YYYY / hh:mm')
          return dateWithOutString;
      } else {
          return '-';
      }    
  }

  const handleClickDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const setPatientStatusChange = (event: SelectChangeEvent<string>) => {
    setPaymentStatus(event.target.value);
    if(event.target.value == "rejected" || event.target.value == "onhold"){
      setReasonValue("");
      setPaymentRejectedOptions(true);
    } else {
      setPaymentRejectedOptions(false);
    }
  };

  const handleReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReasonValue(event.target.value);
  };

  const setPaymentStatusValue = (activeTabIndex: number) => {
    let statusStr = '';
    if(activeTabIndex == 0 && activeTabName == 'payments approved'){
      statusStr = 'Approved';
    } else if(activeTabIndex == 1 && activeTabName == 'processed payments'){
      statusStr = 'Completed';
    }

    return statusStr;
  };

  const handlePaymentStatusDialogSubmit = async () => {
    if(paymentStatus == ""){
      setMessage('Please select any payment status');
      setOpen(true);
      return false;
    }

    let submitPaymentStatuspayloadObj:paymentStatusSubmitPayload = {
      intent: "updatePaymentDetailsByVisitId",
      state: {
          userType: userRoleVal,
          visitId: selPatientVisitId,
          financeApproval: paymentStatus,
          financeActionOn: moment().format('YYYY-MM-DD'),
          financeUserName: localStorage.getItem("userName")
      }
    }

    const submitPaymentStatusResp = await _axios.post(
      `${process.env.REACT_APP_MAIN_SERVER_URL}${process.env.REACT_APP_PROJECT_ID}`,
      submitPaymentStatuspayloadObj,
      {
        headers: {
          version: VERSION,
        },
      }
    );

    if(submitPaymentStatusResp.status == 200 && submitPaymentStatusResp.data.update_trade_partner_payment_details.returning.length != 0){
      getInitialPaymentDetails(activeTab);
      setMessage('Payment status has been updated successfully');
      setOpen(true);
      setOpenDialog(false);
    } else {
      setMessage('Unable to update the Payment status.');
      setOpen(true);
      return false;
    }
    

  };

  const tabMenus = [];
  if (activeTab === 0) {
    tabMenus.push(
    [
      { value: "inprocess", label: "InProcess" },
      { value: "completed", label: "Completed" },
    ]
    );
  } else if (activeTab === 1) {
    tabMenus.push([]);
  }
  const activeTabMenus = tabMenus[0];

  return (
    <>
      <Sidemenu />
      <div className="layoutParent">
        <p className="layoutParentP">Payment Details</p>
        <Snackbar
          ContentProps={{
            "aria-describedby": "message-id",
            className: classes.snackbarStyleViaContentProps
          }}
          open={open}
          autoHideDuration={3000}
          message={message}
          onClose={() => setOpen(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        />
        
        <Dialog open={openDialog} onClose={handleDialogClose}>
          <DialogTitle>Status</DialogTitle>
          <DialogContent>

            <Grid container className="mt2" spacing={3}>
                <Grid item xs={5} className="text-right">
                  <Item style={{ paddingTop: '1.1rem' }}>Payment Status</Item>
                </Grid>
                <Grid item xs={7} className="text-left">
                  <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={paymentStatus}
                      displayEmpty
                      onChange={setPatientStatusChange}
                    >
                      <MenuItem value="">
                        <em>Select</em>
                      </MenuItem>
                      {activeTabMenus.map((menuitem)=>(
                        <MenuItem key={menuitem.value} value={menuitem.value}>{menuitem.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              
            {/* {activeTab === 0 && paymentStatus == "reject" ? ( */}
            {paymentRejectedOptions ? (
                <Grid container className="mt2" spacing={3}>
                  <Grid item xs={5} className="text-right">
                    <Item style={{ paddingTop: '1.1rem' }}>Reason</Item>
                  </Grid>
                  <Grid item xs={7} className="text-left">
                    <TextField
                        label="Reason"
                        multiline
                        rows={4}
                        value={reasonValue}
                        onChange={handleReasonChange}
                      />
                  </Grid>
                </Grid>
              ) : null}  
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose}>Cancel</Button>
            <Button onClick={handlePaymentStatusDialogSubmit}>Submit</Button>
          </DialogActions>
        </Dialog>

        <div
          className="layoutContent"
          style={{ paddingTop: "5px", paddingBottom: "20px" }}
        >
          <div className='conflicts_tabs'>
            <ul>
              {tabsName.map((d, i) => (
                <li
                  className={`${activeTab === i ? 'activeTab' : ''}`}
                  onClick={() => {
                    handleTabClick(i);
                    setActiveTabName(tabsName[i].toLowerCase());
                  }}
                >
                  {d}
                </li>
                ))
              }
            </ul>
        </div>
        <div className="tableWithHadle" ref={PaymentsApprovedTableContainerRef}>
            <table className="data-grid-table">
              <thead style={{ position: 'sticky', top: '0px' }}>
                  <tr>
                      {tableHead.map(d => <th>{d}</th>)}
                      {activeTab == 0 ? <><th>Approved by</th></> : null}
                  </tr>
                </thead>
                <tbody>
                  {docArray.length > 0 ? 
                    docArray.map((d, i) => (
                      <tr key={i}>
                        <td>{d?.patient_thop_map?.patient_uhid ? d?.patient_thop_map?.patient_uhid : "-"}</td>
                        <td>
                          {d?.patient_thop_map?.referred_patient_detail?.dre_trade_partner_map?.medunited_user?.first_name +
                            " " +
                            d?.patient_thop_map?.referred_patient_detail?.dre_trade_partner_map?.medunited_user?.last_name}
                        </td>
                        <td>
                          {d?.patient_thop_map?.referred_patient_detail?.dre_trade_partner_map?.medunited_user?.mobile_number}
                        </td>
                        <td>{d?.patient_thop_map?.referred_patient_detail?.patient_name}</td>
                        <td>{setDateFormat(d?.patient_thop_map?.discharged_on)}</td>
                        <td>{d.calculated_amount}</td>
                        <td>{d.net_amount}</td>
                        
                        <td>
                          {setPaymentStatusValue(activeTab)}&nbsp;
                          {activeTab === 0 ?
                          <>
                          <img className="" src={editIcon} style={{cursor: "pointer"}}
                              onClick={() => {
                              setSelPatient(d.id.toString())
                              setSelPatientVisitId(d.fk_visit_id)
                              handleClickDialogOpen();
                              
                              setPaymentStatus(""); // Set the payment status to an empty string
                              setReasonValue(""); // Optionally reset the reason value as well
                              setPaymentRejectedOptions(false);
                            }} />
                          </>
                          : null}
                          
                        </td>
                        {activeTab == 0 ?
                          <>
                            <td>{d?.sales_head_name ? d?.sales_head_name : "-"}</td>
                          </>
                        : null
                        }
                    </tr>
                  )):
                <tr>
                  <td className="text-center" colSpan={9}>No Data</td>
                </tr>
            }
            </tbody>
            </table>
          </div>
          <Pagination
            pageNo={page}
            totalPages={totalPages}
            previousPage={previousPage}
            nextPage={nextPage}
            rowPerPageChange={rowPerPageChange} />
        </div>
      </div>
    </>
  );
}

export default PaymentDetailsF;
