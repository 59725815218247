export const MID_URL_FORM_RUNTIME_DATA = 'form-runtime/v1/form-data';

export const GRADIENTIMAGECOLOR = 'linear-gradient(to bottom right, #DA4B48, #EB9554)';

export const INSTACONFLICTSTABLEHEADDATA = [
    { field: 'PATIENT_PHONE', headerName: 'Mobile', flex: 1, headerClassName: 'aaa' },
    { field: 'PATIENT_NAME', headerName: 'Patient Name', flex: 1 },
    { field: 'MR_NO', headerName: 'MR Number', flex: 1 },
    { field: 'RATE_PLAN_ID', headerName: 'Rate Plan ID', flex: 1 },
    { field: 'REG_DATE_TIME', headerName: 'Registration Date TIme', flex: 1 }
];

export const REFERRALCONFLICTSTABLEHEADDATA = [
    { field: 'mobileNumber', headerName: 'Mobile', flex: 1, headerClassName: 'aaa' },
    { field: 'name', headerName: 'Patient Name', flex: 1 },
    { field: 'specialityOfTreatment', headerName: 'Speciality Of Treatment', flex: 1 },
    { field: 'referralName', headerName: 'Referral Name', flex: 1 }
];

export const PAYMENTADVISETABLEHEADDATA = [
    { field: 'type', headerName: 'Type', width: 70 },
    { field: 'hospitalAccNo', headerName: 'Hospital Acc No', width: 160 },
    { field: 'amount', headerName: 'Referrer Amount', width: 130 },
    { field: 'currency', headerName: 'Currency', width: 130 },
    { field: 'referrerAccNo', headerName: 'Referrer Account No', width: 130 },
    { field: 'ifsc', headerName: 'IFSC Code', width: 130 },
    { field: 'name', headerName: 'Referrer Name', width: 130 }
];

export const MENULISTONROLEDATA = [
    {
        executive: ['Procedure Update', 'Referrer Patient Conflicts'],
        managingDirector: ['Payment Approval By Director'],

        finance:['Payment Details for Finance'],
        manager: ['Patients Info','Payment Details'],
        doctorReferralExecutive: ['Patients Info','Payment Details'],
        clusterManager: ['Patients Info','Payment Details'],
        assistantGeneralManager: ['Patients Info','Payment Details'],
        salesHead: ['Patients Info','Payment Details'],
    }
];

export const PATIENT_DATA_HEADER = ['Patient UHID','Referrer Name','Referrer Mobile','Patient Name','Patient Number','Status','View','Comments'];

export const PATIENT_DATA_HEADER_ALL = ['Patient UHID','Referrer Name','Referrer Mobile','Patient Name','Patient Number','Status','Executive Name'];
export const PATIENT_DATA_HEADER_REFFERED = ['Patient UHID','Referrer Name','Referrer Mobile','Patient Name','Patient Number','Status','Executive Name', 'Referred Date'];
export const PATIENT_DATA_HEADER_ADMITTED = ['Patient UHID','Referrer Name','Referrer Mobile','Patient Name','Patient Number','Status', 'Referred Date', 'Admitted On', 'Comments'];
export const PATIENT_DATA_HEADER_DISCHARGED = ['Patient UHID','Referrer Name','Referrer Mobile','Patient Name','Patient Number','Status','Admitted Date', 'Reg Date', 'Discharged Date'];
export const PATIENT_DATA_HEADER_ONHOLD = ['Referrer Name','Referrer Mobile','Patient Name','Patient Number','Status','Patient Admission Acknowledged Date','Remarks'];
export const PATIENT_DATA_HEADER_REFERRALLOST = ['Referrer Name','Referrer Mobile','Patient Name','Patient Number','Status','Referred On','Updated On','Remarks'];

export const PROCEDUREUPDATETABS = ['Patient Details', 'Referrer Details'];

export const PATIENT_INFO_TABS = [ 'Referred', 'Admitted', 'Discharged', 'Onhold', 'Referral lost'];

export const FORMID = {
    paymentAcceptanceTranscationId : '1030055364952023040',
    instaReferalPatientId:'960789210929950720',
    commentsId:'1030420482906873856',
    procedureReferId:'1033281457263697920',
    procedureRefereInstaDataId:'1029306794296696832'
}

export const DRE_PATIENT_DATA_HEADER_REFFERED = ['Referrer Name','Referrer Mobile','Patient Name','Patient Mobile', 'Status', 'Referred Date'];