
import React, { useEffect, useState } from 'react';
import { routes as appRoutes } from "./routes";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Provider } from "react-redux";
import StoreService from "./services/StoreService";
import HttpService from './services/HttpService';
import KeycloakService from "./services/KeycloakService";
import { VERSION }  from './constants';

const store = StoreService.setup();

const _axios = HttpService.getAxiosClient();

function App() {
  const [accToken, setAccToken] = useState('');
  const [refreshToken, setRefreshToken] = useState('');
  const [renderedData, setrenderedData] = useState(false);

  useEffect(() => {
    getLogedInUser();
  });

  const getLogedInUser = async() => {
    try{
      const payload = {
        "intent": "getUserByUserId",
        "state": {
            userId : KeycloakService.getSub(),
        }
      }
      
      const userResponse = await _axios.post(`${process.env.REACT_APP_MAIN_SERVER_URL}${process.env.REACT_APP_PROJECT_ID}`, payload, {
        headers: {
          version: VERSION,
          }
      },)

      if(userResponse?.data) {
        const userResponseObj = userResponse.data.medunited_users[0];

        localStorage.setItem('userRole', userResponseObj.user_type);
        localStorage.setItem('userName', userResponseObj.user_name);
        localStorage.setItem('userId', userResponseObj.user_id);
        if(localStorage.getItem('userRole') && localStorage.getItem('userName') && localStorage.getItem('userId'))
        {
          setrenderedData(true);
        }
      }
    }
    catch(e){
      console.log(e)
    }    
  }

  return (
    <Provider store={store}>  
    <Router>
        <Routes>
            {appRoutes.map((route) => (
              <Route
                key={route.key}
                path={route.path}
                element={<route.component />}
              />
            ))}
        </Routes>
    </Router>
    </Provider>
  );
}

export default App;