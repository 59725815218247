import * as React from 'react';
import { DataGrid, GridRowsProp, GridColDef, GridApi, GridCellValue, GridCellModesModel, GridCellParams, GridCellModes } from '@mui/x-data-grid';
import { Autocomplete, Button, FormControl, InputLabel, MenuItem, Select, Snackbar, TextField } from '@mui/material';
import Sidemenu from '../components/Sidemenu';
import { makeStyles } from '@mui/styles';
import FilterIcon from './../assets/images/filter.svg';
import './pages.css';
import HttpService from '../services/HttpService';
import Pagination from '../components/pagination';
import moment from 'moment';
import ReferralDetails from './referralDetails';
import { type } from '@testing-library/user-event/dist/type';
import { FORMID, GRADIENTIMAGECOLOR, MID_URL_FORM_RUNTIME_DATA, PATIENT_DATA_HEADER, PROCEDUREUPDATETABS } from '../utils/constants';
import { PatientData } from '../types/Module_Type';


const _axios = HttpService.getAxiosClient();
const userId = localStorage.getItem('userId');
const patientDataHeader = PATIENT_DATA_HEADER;



interface Comment {
  comment: string
  timeStamp: string
}
type CommentsData = {
  comments: Comment[];
  mrNumber: string;
  patientName: string;
  mobileNumber: string;
}

const tabsName = PROCEDUREUPDATETABS;

const useStyles = makeStyles(() => ({
  snackbarStyleViaContentProps: {
    backgroundImage: GRADIENTIMAGECOLOR,
  }
}));

interface FilmOptionType {
  title: string;
  year: number;
}

type dataArray = {
  label: string;
  value: string;
  year: string
}

const ProcedureUpdate = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [patientArray, setPatientArray] = React.useState<PatientData[]>([]);
  const [show, setShow] = React.useState(false);
  const [aciveTab, setActiveTab] = React.useState(0);
  const [showFilter, setShowFilter] = React.useState(false);
  const [commentsFormData, setCommentsFormData] = React.useState<CommentsData | null>(null);
  const [commentId, setCommentId] = React.useState('');
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(10);
  const [rowPageSize, setRowPageSize] = React.useState(10);
  const [totalPages, setTotalPages] = React.useState(Number);
  const [showModalPopup, setShowModalPopup] = React.useState(false);
  const [commentsModal, setCommentsModal] = React.useState(false);
  const [referrerName, setReferrerName] = React.useState('');
  const [patientName, setPatientName] = React.useState('');
  const [patientStatus, setPatientStatus] = React.useState('admitted');
  const [referrerList, setReferrerList] = React.useState<dataArray[]>([])
  const [value, setValue] = React.useState<FilmOptionType | null>(null);


  const handleTextarea = (index: any) => {
    const objData = patientArray;
    if (objData[index].textareaField === false) {
      objData[index].textareaField = true;
      viewComment(objData[index].MR_NO, index)
    }
    else {
      objData[index].textareaField = false;
    }
    setPatientArray(objData);
    setShow(!show)
  }

  const updateProcedure = (e: any, index: any) => {
    const objData = [...patientArray];
    updateComments(e.target.value, index);
    if (objData[index].textareaField === false) {
      objData[index].textareaField = true;
    }
    else {
      objData[index].textareaField = false;
    }
    setPatientArray(objData);
    setShow(!show)
  }

  const updateComments = async (data: string, index: any) => {
    if (data === '' || data === null || data === undefined) {

    }
    else {
      try {
        const commentsData = { ...commentsFormData };
        const oldComments: Comment[] = commentsData.comments ?? [];
        const newComment: Comment = {
          timeStamp: new Date().toISOString(),
          comment: data
        }
        oldComments?.push(newComment)
        const bodyObj = {
          formId: FORMID.commentsId,
          id: commentId,
          formData: {
            comments: oldComments,
          }
        }
        let resp = await _axios.patch(`${process.env.REACT_APP_MAIN_SERVER_URL}${MID_URL_FORM_RUNTIME_DATA}`, bodyObj);
        setMessage('Added Comment for' + commentsData.patientName);
        setOpen(true);
        if (resp) {
          callNotification(data, index);
        }
      }
      catch (e: any) {
        console.log(e);
        setMessage(e.message);
        setOpen(true);
      }
    }
  }

  const callNotification = async (message: string, i: any) => {
    try {
      const objData = [...patientArray];
      let payload = {
        userId: objData[i].REFERRER_ID,
        message: `Dear ${objData[i].REFERRED_BY}, ${message} has been started for ${objData[i].PATIENT_NAME}`,
        type: 'fireBase'
      }
      await _axios.post(`${process.env.REACT_APP_MAIN_SERVER_URL}insta/v1/notifications`, payload);
    }
    catch (e: any) {
      console.log(e);
      setMessage(e.message);
      setOpen(true);
    }
  }

  const handleReferrerName = (e: any, value: any) => {
    setReferrerName(value.value);
    if (value === '') {
      setReferrerName('');
    }
  }

  const handlePatientStatus = (e: any) => {
    setPatientStatus(e.target.value);
  }

  React.useEffect(() => {
    getInitailPaitentList();

  }, [page, rowPageSize]);

  const fetchReferrerData = async (userId: any) => {
    console.log(userId)
    try {
      let refData = await _axios.get(`${process.env.REACT_APP_MAIN_SERVER_URL}${MID_URL_FORM_RUNTIME_DATA}?formId=${FORMID.procedureReferId}&filter=formData.executiveId:"${userId}"`);
      let newEmptyArray = [{ label: 'Select', value: '', year: '' }];
      let newCreatedListArray = refData.data?.data[0]?.formData?.referrers?.map((d: any) => (
        { label: d.referrerName, value: d.referrerId, year: d.referrerId }
      ));
      let concatedArrayList = newEmptyArray.concat(newCreatedListArray)
      console.log(newEmptyArray.concat(newCreatedListArray));
      setReferrerList(concatedArrayList)
      // setReferrerList(refData.data?.data[0]?.formData?.referrers?.map((d:any)=>(
      //   {label:d.referrerName,value:d.referrerId, year:d.referrerId}
      // )))
    }
    catch (e: any) {
      console.log(e);
      setMessage('fetchReferrerData' + e.message);
      setOpen(true);
    }
  }

  const getInitailPaitentList = async () => {
    try {
      const rName = referrerName ? `formData.REFERRER_ID:"${referrerName}";` : '';
      const rStatus = patientStatus ? `formData.PATIENT_STATUS:"${patientStatus}";` : '';
      let data: any = [];

      data = await _axios.get(`${process.env.REACT_APP_MAIN_SERVER_URL}${MID_URL_FORM_RUNTIME_DATA}?formId=${FORMID.procedureRefereInstaDataId}&filter=${rName}${rStatus}formData.EXECUTIVE_ID:${localStorage.getItem('userId')}&page=${page - 1}&size=${rowPageSize}`);
      if (data.data.data?.content?.length > 0) {
        setPatientArray(data.data.data.content.map((d: any) => d.formData));
        setTotalPages(data.data.data.totalPages);
      }
      if (data.data.data.length > 0) {
        setPatientArray(data.data.data.map((d: any) => d.formData));
      }
      if (data.data.data?.content?.length === 0) {
        setPatientArray([]);
      }
      setTimeout(() => {
        fetchReferrerData(userId);
      }, 1500)
    }
    catch (e: any) {
      console.log(e);
      setMessage('getInitailPaitentList' + e.message);
      setOpen(true);
    }
  }

  const handleFilterApply = () => {
    getInitailPaitentList();
  }

  const previousPage = () => {
    let previousPageNo = page - 1;
    setPage(previousPageNo);
  }
  const nextPage = () => {
    let nextPageNo = page + 1;
    setPage(nextPageNo);
  }
  const rowPerPageChange = (e: any) => {
    let val = e.target.value;
    setRowPageSize(val);
  }

  const handleTabClick = (val: string, index: number) => {
    setActiveTab(index)
  }

  const handleFilter = () => {
    setShowFilter(!showFilter);
  }

  const closeFilter = () => {
    setShowFilter(false);
  }

  const viewCommentsModal = (MRNO: string, index: any) => {
    viewComment(MRNO, index);
    setCommentsModal(true);
    setShowModalPopup(true)
  }

  const viewComment = async (MRNO: string, index: any) => {
    try {
      let dataa = await _axios.get(`${process.env.REACT_APP_MAIN_SERVER_URL}${MID_URL_FORM_RUNTIME_DATA}?filter=formData.mrNumber:"${MRNO}"&formId=${FORMID.commentsId}`);
      if (dataa.data.data.length > 0) {
        setCommentsFormData(dataa.data.data[0]?.formData);
        setCommentId(dataa.data.data[0].id)
      }
      else {

      }
    }
    catch (e: any) {
      console.log(e);
      setMessage(e.message);
      setOpen(true);
    }
  }

  const cancelModalPopup = () => {
    setShowModalPopup(false)
    setCommentsFormData(null)
  }

  const setDateFormat = (date: String) => {
    let dateWithOutString = date.replace(/"/gi, "");
    dateWithOutString = moment(dateWithOutString).format('DD-MMM-YYYY / hh:mm')
    return dateWithOutString;
  }

  return (
    <>
      <Sidemenu />
      <div className='layoutParent'>
        <Snackbar
          ContentProps={{
            "aria-describedby": "message-id",
            className: classes.snackbarStyleViaContentProps
          }}
          open={open}
          autoHideDuration={3000}
          message={message}
          onClose={() => setOpen(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        />

        {showModalPopup &&
          <>
            <div className="overlay"></div>
            <div className="modalPopup">
              <h3>Patient Status</h3>
              <div className='handleHeight'>
                {commentsFormData?.comments ?? [].length > 0 ?
                  commentsFormData?.comments.map(d => (
                    <div className='comments_view_modal'>
                      <p>{d.comment}</p>
                      <span>{setDateFormat(d.timeStamp)}</span>
                    </div>
                  )) : <p className='text-center'>~No Comments Added Yet.~</p>}
              </div>
              <button className="closeBtn" onClick={cancelModalPopup}>Close</button>
            </div>
          </>
        }

        <div
          className="layoutContent">
         
            <div className='conflicts_tabs'>
              <ul>
                {tabsName.map((d, i) => <li className={`${aciveTab === i ? 'activeTab' : ''}`} onClick={() => handleTabClick(d, i)}>{d}</li>)}
              </ul>
            </div>

          {aciveTab === 0 &&
            <div className='text-right filterBtnCover'><button onClick={handleFilter}> <img src={FilterIcon} /> Filter</button></div>}
          {showFilter &&
            <div className='filterBox'>
              <div className='FiltersList'>
                <div className='autoComplete'>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={referrerList}
                    onChange={handleReferrerName}
                    clearIcon={false}
                    renderInput={(params) => <TextField {...params} label="Referrer Name" />}
                  />
                </div>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-helper-label">Select Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={patientStatus}
                    label="Select Status"
                    onChange={handlePatientStatus}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="admitted">Admitted</MenuItem>
                    <MenuItem value="discharged">Discharged</MenuItem>
                  </Select>
                </FormControl>

                <div className='applyFilterDiv'>
                  <button className='applyBtn' onClick={handleFilterApply}>Apply</button>
                  <button onClick={closeFilter} className='closeBtn'>Close Filter</button>
                </div>
              </div>

            </div>}
          {aciveTab === 0 ?
            <div className='tableWithHadle'>
              <table className='data-grid-table'>
                <thead>
                  <tr>
                      {patientDataHeader.map((d, i) => <th key={i}><span></span>{d}</th>)}
                   </tr>
                </thead>
                <tbody>
                    {patientArray.length > 0 ?
                      patientArray.map((d, i) => (
                        <tr key={i}>
                          <td>{d.MR_NO}</td>
                          <td>{d.REFERRED_BY}</td>
                          <td>{d.REFERRER_MOBILE_NUMBER}</td>
                          <td>{d.PATIENT_NAME}</td>
                          <td>{d.PATIENT_PHONE}</td>
                          <td>{d.PATIENT_STATUS}</td>
                          <td><span className='viewBtn' onClick={() => viewCommentsModal(d.MR_NO, i)}>View comments</span></td>
                          <td>
                            {show ? '' : null}
                            {d?.textareaField === true ?
                              <textarea className='textareaField' placeholder='Enter Procedures...' onBlur={(e) => updateProcedure(e, i)}></textarea>
                              :
                              <button className='addCommentBtn' onClick={() => handleTextarea(i)}>Add Comment </button>
                            }
                          </td>
                        </tr>
                      )) :
                      <tr>
                        <td className='text-center' colSpan={8}>No Patient Details available</td>
                      </tr>
                    }
                  </tbody>
              </table>
              <Pagination
                pageNo={page}
                totalPages={totalPages}
                previousPage={previousPage}
                nextPage={nextPage}
                rowPerPageChange={rowPerPageChange}
              />
            </div>
            : ''}

          {aciveTab === 1 ? <ReferralDetails /> : null}
        </div>

      </div>
    </>
  );
}

export default ProcedureUpdate;