import { makeStyles } from "@mui/styles";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import Pagination from "../../components/pagination";
import HttpService from "../../services/HttpService";
import { GRADIENTIMAGECOLOR, PATIENT_DATA_HEADER_REFERRALLOST } from "../../utils/constants";
import KeycloakService from "../../services/KeycloakService";
import { VERSION } from "../../constants";
import { PatientDataManager } from "../../types/Module_Type";
import { Snackbar } from '@mui/material';

const _axios = HttpService.getAxiosClient();
const userRoleVal = localStorage.getItem("userRole");

type Payload = {
    intent: string;
    state: {
      limit: number;
      offset: number;
      patientStatus: string;
      startDate: string,
      endDate: string,
      dreId?: string;
      clusterManagerId?: string;
      managerId?: string;
      agmId?: string;
      salesHeadId?: string;
      financeId?: string;
    };
  };
   const useStyles = makeStyles(() => ({
    snackbarStyleViaContentProps: {
      backgroundImage: GRADIENTIMAGECOLOR,
    },
  }));

const PatientInfoReferralLost = ({
    selectedVisitType,
    filteredData,
    filterApplied
  } : {
    selectedVisitType: string;
    filteredData: PatientDataManager;
    filterApplied: boolean
  }) => {    
    const classes = useStyles();
    const [patientArrayM, setPatientArrayM] = React.useState<PatientDataManager[]>([]);
    const [page, setPage] = React.useState(1);
    const [rowPageSize, setRowPageSize] = React.useState(10);
    const [totalPages, setTotalPages] = React.useState(Number);
    const [offsetVal, setOffsetVal] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState('');

    useEffect(() => {
        getInitialPaitentList();
    }, [page, rowPageSize, selectedVisitType, filteredData, filterApplied]);

    const getInitialPaitentList = async () => {
        try {
            setPatientArrayM([]);
            if(filterApplied){
                if(filteredData && filteredData?.patient_thop_map?.length > 0){
                    setPatientArrayM(filteredData.patient_thop_map);
                    setTotalPages(Math.ceil(filteredData?.patient_thop_map_aggregate?.aggregate.count / rowPageSize));
                    setOffsetVal((rowPageSize * page) - rowPageSize);
                } else {
                    // Render message indicating no data found for the applied filter
                    setPatientArrayM([]);
                    setTotalPages(1);
                }
            } else {
                let intentStr = userRoleVal === 'doctorReferralExecutive' ? 'getRefferedAndReferralLostPatientsForDreByDate'
                  : userRoleVal === 'clusterManager' ? 'getAllReferredAndReferralLostPatientForClusterManager'
                  : userRoleVal === 'manager' ? 'getAllReferredAndReferralLostPatientsForManager'
                  : userRoleVal === 'assistantGeneralManager' ? 'getAllReferredAndReferralLostPatientsForAssistantGeneralManager'
                  : userRoleVal === 'salesHead' ? 'getAllReferredAndReferralLostPatientForSalesHead'
                  : '';

                const referralLostPatientsPayload:Payload = {
                    intent: intentStr,
                    state: {
                        patientStatus: "referralLost",
                        limit: rowPageSize,
                        offset: offsetVal,
                        // startDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
                        startDate: '2023-01-01',
                        endDate: moment().format('YYYY-MM-DD'),
                    },
                }

                if (userRoleVal === 'doctorReferralExecutive') {
                    referralLostPatientsPayload.state.dreId = KeycloakService.getSub();
                } else if (userRoleVal === 'clusterManager') {
                    referralLostPatientsPayload.state.clusterManagerId = KeycloakService.getSub();
                } else if (userRoleVal === 'manager') {
                    referralLostPatientsPayload.state.managerId = KeycloakService.getSub();
                } else if (userRoleVal === 'assistantGeneralManager') {
                    referralLostPatientsPayload.state.agmId = KeycloakService.getSub();
                } else if (userRoleVal === 'salesHead') {
                    referralLostPatientsPayload.state.salesHeadId = KeycloakService.getSub();
                }

                const referralLostPatientsResp = await _axios.post(
                `${process.env.REACT_APP_MAIN_SERVER_URL}${process.env.REACT_APP_PROJECT_ID}`,referralLostPatientsPayload,
                    {
                        headers: {
                        version: VERSION,
                        },
                    }
                );
                
                const referralLostPatientsRespData = referralLostPatientsResp.data;

                if (
                    referralLostPatientsRespData &&
                    referralLostPatientsRespData.referred_patient_details.length != 0
                ) {
                    const sortedRefLostPatientsArray = referralLostPatientsRespData.referred_patient_details.sort((a: { updated_at: string }, b: { updated_at: string }) => {
                        const dateA = new Date(a.updated_at);
                        const dateB = new Date(b.updated_at);
                      
                        return dateB.getTime() - dateA.getTime();
                    });

                    setPatientArrayM(sortedRefLostPatientsArray);
                    setTotalPages(
                        Math.ceil(referralLostPatientsRespData.referred_patient_details_aggregate.aggregate
                        .count / rowPageSize)
                    );
                    setOffsetVal((rowPageSize * page) - rowPageSize);
                } else {
                    setPatientArrayM([]);
                    setTotalPages(1);
                }
                
                if (referralLostPatientsRespData.referred_patient_details_aggregate.aggregate
                    .count === 0) {
                    setPatientArrayM([]);
                }
            }
        }
        catch (e: any) {
            console.log(e);
            setMessage('getInitialPaitentList' + e.message);
            setOpen(true);
        }
    }

    const previousPage = () => {
        let previousPageNo = page - 1;
        setPage(previousPageNo);
        setOffsetVal(offsetVal - rowPageSize);
    }
    const nextPage = () => {
        let nextPageNo = page + 1;
        setPage(nextPageNo);
        setOffsetVal(offsetVal + rowPageSize);
    }
    const rowPerPageChange = (e: any) => {
        let val = e.target.value;
        setRowPageSize(val);
    }

    const setDateFormat = (date:String) => {
        if(date != null && date != ""){
            let dateWithOutString = date.replace(/"/gi, "");
            dateWithOutString = moment(dateWithOutString).format('DD-MMM-YYYY / hh:mm')
            return dateWithOutString;
        } else {
            return '-';
        }
    }

    const referralLostPatientsTableContainerRef = useRef<HTMLDivElement | null>(null)
    useEffect(() => {
        const resizeHandler = () => {
            const container = referralLostPatientsTableContainerRef.current
            if (container) {
            const availableHeight: any =
                window.innerHeight - container.getBoundingClientRect().top
            container.style.maxHeight = `${availableHeight - 90}px`
            }
        }

        window.addEventListener('resize', resizeHandler)
        resizeHandler()

        return () => {
            window.removeEventListener('resize', resizeHandler)
        }
    }, [])


    return (
        <>
            <div className='tableWithHadle' ref={referralLostPatientsTableContainerRef}>
                <Snackbar
                    ContentProps={{
                    "aria-describedby": "message-id",
                    className: classes.snackbarStyleViaContentProps
                    }}
                    open={open}
                    autoHideDuration={3000}
                    message={message}
                    onClose={() => setOpen(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                />
                <table className='data-grid-table'>
                    <thead style={{ position: 'sticky', top: '0px' }}>
                        <tr> {PATIENT_DATA_HEADER_REFERRALLOST.map((d, i) => <th key={i}><span></span>{d}</th>)} </tr>
                    </thead>
                    <tbody>
                        {patientArrayM.length > 0 ?
                            patientArrayM.map((d, i) => (
                                <tr key={i}>
                                    <td>
                                    {d?.dre_trade_partner_map?.medunited_user?.first_name +
                                        " " +
                                        d?.dre_trade_partner_map?.medunited_user?.last_name}
                                    </td>
                                    <td>{d.dre_trade_partner_map?.medunited_user?.mobile_number}</td>
                                    <td>{d.patient_name}</td>
                                    <td>{d.mobile_number}</td>
                                    <td>{d.patient_status}</td>
                                    <td>{setDateFormat(d.created_at)}</td>
                                    <td>{setDateFormat(d.updated_at)}</td>
                                    <td>{d.patient_reason}</td>
                                </tr>
                            )) :
                            <tr>
                                <td className='text-center' colSpan={9}>No Patient Details available</td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
            <Pagination
                pageNo={page}
                totalPages={totalPages}
                previousPage={previousPage}
                nextPage={nextPage}
                rowPerPageChange={rowPerPageChange}
            />
        </>
    );
}
export default PatientInfoReferralLost;
