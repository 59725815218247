import React, { FC, useState } from 'react';
import { createBrowserHistory } from 'history';
import { useEffect } from 'react';
import PaymentApprovalbyDirector from './paymentApprovalD';
import { routes } from "../routes";
import HttpService from '../services/HttpService';
import KeycloakService from '../services/KeycloakService';
import ProcedureUpdate from './procedureUpdate';
import ReferrerPatientConflicts from './conflicts/conflicts';
import PaymentApproval from './paymentApproval';
import PaymentAdvise from './PaymentAdvise';
import PatientInfoByManager from './patientInfo/procedureUpdateM';
import PaymentDetails from './paymentDetails'
import PaymentDetailsF from '../pages/paymentDetailsF'
import { VERSION }  from '../constants';

const _axios = HttpService.getAxiosClient();
const history = createBrowserHistory();

type menuType= {
    executive: string[];
    managingDirector: string[];

    finance: string[];
    doctorReferralExecutive: string[];
    clusterManager: string[];
    manager: string[];
    assistantGeneralManager: string[];
    salesHead: string[];
  }
  
  let menuList:menuType[] = [
    {
      executive:['Procedure Update', 'Referrer Patient Conflicts'],
      managingDirector:['Payment Approval By Director'],

      finance:['Payment Details for Finance'],
      doctorReferralExecutive: ['Patients Info','Payment Details'],
      clusterManager: ['Patients Info','Payment Details'],
      manager: ['Patients Info','Payment Details'],
      assistantGeneralManager: ['Patients Info','Payment Details'],
      salesHead: ['Patients Info','Payment Details'],

    }
  ];

type menuArray ={
    key: string,
    title: string,
    path: string,
    enabled: boolean,
    component: FC<{}>,
    hideIt:boolean,
} 

const RedirectPage = () => {
    const [menus, setMenus] = useState<menuArray[]>([]);

    useEffect(() => {
        getLogedInUser();
    }, [])

    const getLogedInUser = async () => {
        try {

            const payload = {
                "intent": "getUserByUserId",
                "state": {
                    userId : KeycloakService.getSub(),
                }
              }
              
              const userResponse = await _axios.post(`${process.env.REACT_APP_MAIN_SERVER_URL}${process.env.REACT_APP_PROJECT_ID}`, payload, {
                headers: {
                  version: VERSION,
                  }
              },)

              
              if(userResponse?.data) {
                localStorage.setItem('userRole', userResponse.data.medunited_users[0].user_type);
                updateMenus(userResponse.data.medunited_users[0].user_type);
              }
        }
        catch (e) {
            console.log(e)
        }
    }
    const updateMenus = (userTypess:string) => {
        let allMenus = [...routes];
        let filteredMenus = allMenus.filter(d=>menuList[0][userTypess as keyof menuType].includes(d.title));
        setMenus(filteredMenus)
        // history.push(filteredMenus[0].path);
    }
    return(
        <>
            {menus?.[0]?.path === '/procedure-update/' &&  <ProcedureUpdate />} 
            {menus?.[0]?.path === '/patients-info/' &&  <PatientInfoByManager />} 
            
            {menus?.[0]?.path === '/referrer-patient-conflicts/' && <ReferrerPatientConflicts />}
            {menus?.[0]?.path === '/referrer-patient-conflicts/' && <ReferrerPatientConflicts />}
            {menus?.[0]?.path === '/payment-approval/' && <PaymentApproval />}
            {menus?.[0]?.path === '/payment-approval-by-director/' && <PaymentApprovalbyDirector />} 
            {menus?.[0]?.path === '/payment-advise/' && <PaymentAdvise />}
            {menus?.[0]?.path === '/payment-details/' && <PaymentDetails />}
            {menus?.[0]?.path === '/payment-details-finance/' && <PaymentDetailsF />}
            {/* <PaymentApprovalbyDirector /> */}
        </>
    )

}

export default RedirectPage;