import React, { useEffect } from "react";
import Pagination from "../components/pagination";
import HttpService from "../services/HttpService";
import KeycloakService from "../services/KeycloakService";
import { makeStyles } from '@mui/styles';
import { Snackbar } from "@mui/material";
import { FORMID, GRADIENTIMAGECOLOR, MID_URL_FORM_RUNTIME_DATA } from "../utils/constants";


const _axios = HttpService.getAxiosClient();

const referrerDataHeader = ['Referrer Name', 'Referrer Mobile', 'Referrer Address'];

type dataArray = {
  referrerId: string;
  referrerName: string;
  referrerMobileNumber: string;
  address:string
}

const userId = localStorage.getItem('userId'); 

const useStyles = makeStyles(() => ({
  snackbarStyleViaContentProps: {
    backgroundImage: GRADIENTIMAGECOLOR,
  }
}));

const ReferralDetails = () => {
  const classes = useStyles();
  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(Number);
  const [rowPageSize, setRowPageSize] = React.useState(10);
  const [referrerList, setReferrerList] = React.useState<dataArray[]>([]);
  const [message, setMessage] = React.useState('');
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    fetchReferrerData(userId);
  },[]);

  const fetchReferrerData = async (userId: any) => {
    try {
      let refData = await _axios.get(`${process.env.REACT_APP_MAIN_SERVER_URL}${MID_URL_FORM_RUNTIME_DATA}?formId=${FORMID.procedureReferId}&filter=formData.executiveId:"${localStorage.getItem('userId')}"`);
      setReferrerList(refData?.data?.data[0]?.formData?.referrers)
    }
    catch (e:any) {
      console.log(e);
      setMessage(e.message);
      setOpen(true);
    }
  }

  const previousPage = () => {
    let previousPageNo = page - 1;
    setPage(previousPageNo);
  }
  const nextPage = () => {
    let nextPageNo = page + 1;
    setPage(nextPageNo);
  }
  const rowPerPageChange = (e: any) => {
    let val = e.target.value;
    setRowPageSize(val);
  }


  return (
    <div>
      <Snackbar
        ContentProps={{
          "aria-describedby": "message-id",
          className: classes.snackbarStyleViaContentProps
        }}
        open={open}
        autoHideDuration={3000}
        message={message}
        onClose={() => setOpen(false)}
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
      />
      <div className='tableWithHadle'>
        <table className='data-grid-table'>
          <thead>
            <tr>
              {referrerDataHeader.map((d, i) => <th key={i}><span></span>{d}</th>)}
            </tr>
          </thead>
          <tbody>
            {referrerList?.length > 0 ?
            referrerList.map((d, i) => (
              <tr key={i}>
                <td>{d.referrerName}</td>
                <td>{d.referrerMobileNumber}</td>
                <td>{d.address}</td>
              </tr>
            )):
            <tr>
              <td className="text-center" colSpan={3}>No Referrer Data is available</td>
            </tr>
            }

          </tbody>
        </table>
        <Pagination
          pageNo={page}
          totalPages={totalPages}
          previousPage={previousPage}
          nextPage={nextPage}
          rowPerPageChange={rowPerPageChange}
        />
      </div>
    </div>
  );
}

export default ReferralDetails;