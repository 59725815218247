import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import Pagination from "../../components/pagination";
import HttpService from "../../services/HttpService";
import {
  GRADIENTIMAGECOLOR,
  DRE_PATIENT_DATA_HEADER_REFFERED,
} from "../../utils/constants";
import { VERSION } from "../../constants";
import KeycloakService from "../../services/KeycloakService";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Grid, TextField, Snackbar } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Paper from '@mui/material/Paper';
import editIcon from '../../assets/images/edit.svg';
import { PatientDataManager } from "../../types/Module_Type";

 const useStyles = makeStyles(() => ({
  snackbarStyleViaContentProps: {
    backgroundImage: GRADIENTIMAGECOLOR,
  },
}));

const _axios = HttpService.getAxiosClient();
const userRoleVal = localStorage.getItem("userRole");

const PatientInfoReferred = ({
  selectedVisitType,
  filteredData,
  filterApplied
} : {
  selectedVisitType: string;
  filteredData: PatientDataManager;
  filterApplied: boolean
}) => {
  const classes = useStyles();
  const [patientArrayM, setPatientArrayM] = React.useState<
    PatientDataManager[]
  >([]);
  const [page, setPage] = React.useState(1);
  const [rowPageSize, setRowPageSize] = React.useState(10);
  const [totalPages, setTotalPages] = React.useState(Number);
  const [open, setOpen] = React.useState(false);
  const [offsetVal, setOffsetVal] = React.useState(0);

  type Payload = {
    intent: string;
    state: {
      limit: number;
      offset: number;
      patientStatus: string;
      startDate: string,
      endDate: string,
      dreId?: string;
      clusterManagerId?: string;
      managerId?: string;
      agmId?: string;
      salesHeadId?: string;
      visitType?: string;
      financeId?: string;
    };
  };

  // const [patientStatus, setPatientStatus] = React.useState<patientDataStatus[]>(
  //   []
  // );

  const [openDialog, setOpenDialog] = React.useState(false);

  const [admittedDate, setAdmittedDate] = React.useState<Date | null>(null);
  const [arrivedDate, setArrivedDate] = React.useState<Date | null>(null);
  const [ackAdmisnDate, setAckAdmisnDate] = React.useState<Date | null>(null);

  const handleClickDialogOpen = () => {
    setPatientStatus("");
    setAdmittedOptions(false);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const [visitType, setVisitType] = React.useState('');
  const [patientStatus, setPatientStatus] = React.useState('');
  const [admittedOptions, setAdmittedOptions] = React.useState(false);
  const [onHoldOptions, setOnHoldOptions] = React.useState(false);
  const [refLostOptions, setRefLostOptions] = React.useState(false);
  const [validationMessage, setValidationMessage] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [patientUHIDValue, setPatientUHIDValue] = useState<string>('');
  const [remarksValue, setRemarksValue] = useState<string>('');
  const [reasonValue, setReasonValue] = useState<string>('');
  const [selPatient, setSelPatient] = React.useState('');
  const [dialogTitleStr, setDialogTitleStr] = React.useState('');

  const handleVisitTypeChange = (event: SelectChangeEvent) => {
    setVisitType(event.target.value);
    setAdmittedDate(null);
    setArrivedDate(null);
    setAckAdmisnDate(null);
  };

  const setPatientStatusChange = (event: SelectChangeEvent<string>) => {
    setPatientStatus(event.target.value);

    setAdmittedOptions(false);
    setOnHoldOptions(false);
    setRefLostOptions(false);

    setAdmittedDate(null);
    setArrivedDate(null);
    setAckAdmisnDate(null);
    setPatientUHIDValue('');
    setVisitType('');
    setRemarksValue('');
    setReasonValue('');
    if(event.target.value === "admitted") {
      setAdmittedOptions(true);
    } else if(event.target.value === "onhold"){
      setOnHoldOptions(true);
    } else if(event.target.value === "referralLost"){
      setRefLostOptions(true);
    }
  };

  useEffect(() => {
    getInitialPaitentList();
  }, [page, rowPageSize, selectedVisitType, filteredData, filterApplied]);

  const handlePatientUHIDChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPatientUHIDValue(event.target.value);
  };

  const handleRemarksChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRemarksValue(event.target.value);
  };

  const handleReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReasonValue(event.target.value);
  };

  const getInitialPaitentList = async () => {
    try {
      setPatientArrayM([]);
      if(filterApplied){
          if(filteredData && filteredData.referred_patient_details.length > 0){
              setPatientArrayM(filteredData.referred_patient_details);
              setTotalPages(Math.ceil(filteredData.referred_patient_details_aggregate.aggregate.count / rowPageSize));
              setOffsetVal((rowPageSize * page) - rowPageSize);
          } else {
              // Render message indicating no data found for the applied filter
              setPatientArrayM([]); 
              setTotalPages(1);            
          }
      } else {
        let intentStr = userRoleVal === 'doctorReferralExecutive' ? 'getRefferedAndReferralLostPatientsForDreByDate'
          : userRoleVal === 'clusterManager' ? 'getAllReferredAndReferralLostPatientForClusterManager'
          : userRoleVal === 'manager' ? 'getAllReferredAndReferralLostPatientsForManager'
          : userRoleVal === 'assistantGeneralManager' ? 'getAllReferredAndReferralLostPatientsForAssistantGeneralManager'
          : userRoleVal === 'salesHead' ? 'getAllReferredAndReferralLostPatientForSalesHead'
          : '';

        const refferedPatientsPayload:Payload = {
        intent: intentStr,
          state: {
            patientStatus: "referred",
            limit: rowPageSize,
            offset: offsetVal,
            /* need to fetch records based on date, for last 30 days */
            // startDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
            startDate: '2023-01-01',
            endDate: moment().format('YYYY-MM-DD'),
          },
        }

        if (userRoleVal === 'doctorReferralExecutive') {
          refferedPatientsPayload.state.dreId = KeycloakService.getSub();
        } else if (userRoleVal === 'clusterManager') {
          refferedPatientsPayload.state.clusterManagerId = KeycloakService.getSub();
        } else if (userRoleVal === 'manager') {
          refferedPatientsPayload.state.managerId = KeycloakService.getSub();
        } else if (userRoleVal === 'assistantGeneralManager') {
          refferedPatientsPayload.state.agmId = KeycloakService.getSub();
        } else if (userRoleVal === 'salesHead') {
          refferedPatientsPayload.state.salesHeadId = KeycloakService.getSub();
        }

        const refferedPatientsResp = await _axios.post(
          `${process.env.REACT_APP_MAIN_SERVER_URL}${process.env.REACT_APP_PROJECT_ID}`,
          refferedPatientsPayload,
          {
            headers: {
              version: VERSION,
            },
          }
        );

        const refferedPatientsRespData = refferedPatientsResp.data;

        if (
          refferedPatientsRespData &&
          refferedPatientsRespData.referred_patient_details.length != 0
        ) {
          const sortedRefferedPatientsArray = refferedPatientsRespData.referred_patient_details.sort((a: { created_at: string }, b: { created_at: string }) => {
            const dateA = new Date(a.created_at);
            const dateB = new Date(b.created_at);
          
            return dateB.getTime() - dateA.getTime();
          });
          
          setPatientArrayM(sortedRefferedPatientsArray);
          setTotalPages(
            Math.ceil(refferedPatientsRespData.referred_patient_details_aggregate.aggregate
              .count / rowPageSize)
          );
          setOffsetVal((rowPageSize * page) - rowPageSize);
        } else {
          setPatientArrayM([]);
          setTotalPages(1);
        }
      }
    } catch (e: any) {
      console.log(e);
      setMessage("getInitialPaitentList" + e.message);
      setOpen(true);
    }
  };

  const previousPage = () => {
    let previousPageNo = page - 1;
    setPage(previousPageNo);
    setOffsetVal(offsetVal - rowPageSize);
  };
  const nextPage = () => {
    let nextPageNo = page + 1;
    setPage(nextPageNo);
    setOffsetVal(offsetVal + rowPageSize);
  };
  const rowPerPageChange = (e: any) => {
    let val = e.target.value;
    setRowPageSize(val);
  };

  const setDateFormat = (date: String) => {
    if(date != null && date != ""){
      let dateWithOutString = date.replace(/"/gi, "");
      dateWithOutString = moment(dateWithOutString).format("DD-MMM-YYYY / hh:mm");
      return dateWithOutString;
    } else {
      return '-';
    }
  };

  const referredPatientsTableContainerRef = useRef<HTMLDivElement | null>(null)
    useEffect(() => {
      const resizeHandler = () => {
        const container = referredPatientsTableContainerRef.current
        if (container) {
          const availableHeight: any =
            window.innerHeight - container.getBoundingClientRect().top
          container.style.maxHeight = `${availableHeight - 90}px`
        }
      }

      window.addEventListener('resize', resizeHandler)
      resizeHandler()

      return () => {
        window.removeEventListener('resize', resizeHandler)
      }
  }, [])

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    boxShadow: 'none'
  }));



  const handleDialogSubmit = async () => {
    if(patientStatus == ""){
      setMessage('Please select any status for the patient');
      setOpen(true);
      return false;
    }

    const isValidInput = /^MUH\d{8}$/;
    if (!isValidInput.test(patientUHIDValue)) {
      setMessage('Invalid UHID Number');
      setOpen(true);
      return false;
    }

    let intentStr = "";
    let patientStatusStr = "";
    let resourceStr = "";
    let selDateVal = "";

    let payloadObj = {};

    if(patientStatus === "admitted") {
      resourceStr = visitType;
      let dateKeyStr = "";

      if(visitType == "ipd"){
        intentStr = "ipAdmitApi";
        patientStatusStr = "admitted";
        dateKeyStr = "admittedOn";
        selDateVal = admittedDate ? admittedDate.toISOString() : '';
      } else if(visitType == "opd"){
        intentStr = "createPatientOpArrivedRecord";
        patientStatusStr = "arrived";
        dateKeyStr = "arrivedOn";
        selDateVal = arrivedDate ? arrivedDate.toISOString() : '';
      }

      if(visitType == "ipd"){

        payloadObj = {
          intent: intentStr,
          "state":{
            "updatedBy": localStorage.getItem("userName"),
            "patientUhid": patientUHIDValue,
            "visitType": visitType,
            "patientStatus": patientStatusStr,
            "admittedOn" : selDateVal,
            "patientId": selPatient,
            "resource": resourceStr,
            "userRole": localStorage.getItem("userRole")
          }
        };
      } else if(visitType == "opd"){
        payloadObj = {
          intent: intentStr,
          "state":{
            "updatedBy": localStorage.getItem("userName"),
            "patientUhid": patientUHIDValue,
            "visitType": visitType,
            "patientStatus": patientStatusStr,
            "arrivedOn" : selDateVal,
            "patientId": selPatient,
            "resource": resourceStr,
            "userRole": localStorage.getItem("userRole")
          }
        };
      }

    } else if(patientStatus === "onhold"){
      
      intentStr = "updatePatientStatusToOnhold";
      selDateVal = ackAdmisnDate ? ackAdmisnDate.toISOString() : '';


      payloadObj = {
        intent: intentStr,
        "state":{
          "patientId": selPatient,
          "updatedBy": localStorage.getItem("userName"),
          "userRole": localStorage.getItem("userRole"),
          "patientStatus": patientStatus,
          "patientAdmitAcknowledgedDate": selDateVal,
          "remarks": remarksValue,
        }
      };
    } else if(patientStatus === "referralLost"){
      intentStr = "updatePatientReferralLostStatus";
      

      payloadObj = {
        intent: intentStr,
        "state":{
          "patientId": selPatient,
          "updatedBy": localStorage.getItem("userName"),
          "userRole": localStorage.getItem("userRole"),
          "patientStatus": patientStatus,
          "remarks": reasonValue,
        }
      };
    }

    const refferedStatusChangeResp = await _axios.post(
      `${process.env.REACT_APP_MAIN_SERVER_URL}${process.env.REACT_APP_PROJECT_ID}`,
      payloadObj,
      {
        headers: {
          version: VERSION,
        },
      }
    );

    if(refferedStatusChangeResp.status == 200 && refferedStatusChangeResp.data.update_referred_patient_details_by_pk.length != 0){
      getInitialPaitentList();
      setMessage('Patient status for '+dialogTitleStr+' has been updated successfully');
      setOpen(true);
      setOpenDialog(false);
  } else {
      setMessage('Unable to update the patient '+dialogTitleStr+' status.');
      setOpen(true);
      return false;
  }

    console.log("its done");
    
  };

  return (
    <>
      <div className="tableWithHadle" ref={referredPatientsTableContainerRef}>
        <Snackbar
            ContentProps={{
              "aria-describedby": "message-id",
              className: classes.snackbarStyleViaContentProps
            }}
            open={open}
            autoHideDuration={3000}
            message={message}
            onClose={() => setOpen(false)}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          />

          <Dialog open={openDialog} onClose={handleDialogClose}>
              <DialogTitle>{dialogTitleStr + " - Status"}</DialogTitle>
              <DialogContent>

              <Grid container className="mt2" spacing={3}>
                  <Grid item xs={5} className="text-right">
                    <Item style={{ paddingTop: '1.1rem' }}>Patient Status</Item>
                  </Grid>
                  <Grid item xs={7} className="text-left">
                    <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={patientStatus}
                        displayEmpty
                        onChange={setPatientStatusChange}
                      >
                        <MenuItem value="">
                          <em>Select</em>
                        </MenuItem>
                        <MenuItem value="admitted">Admitted</MenuItem>
                        <MenuItem value="onhold">onHold</MenuItem>
                        <MenuItem value="referralLost">Referral Lost</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
              </Grid>
              {admittedOptions ? 
                <>
                <Grid container className="mt2" spacing={3}>
                    <Grid item xs={5} className="text-right">
                      <Item style={{ paddingTop: '2rem' }}>Patient UHID</Item>
                    </Grid>
                    <Grid item xs={7} className="text-left" style={{ paddingLeft: "33px" }}>
                      <TextField
                        autoFocus
                        required
                        margin="dense"
                        label="Patient UHID"
                        type="text"
                        variant="standard"
                        value={patientUHIDValue}
                        onChange={handlePatientUHIDChange}
                      />
                    </Grid>
                </Grid>
                <Grid container className="mt2" spacing={3}>
                      <Grid item xs={5} className="text-right">
                        <Item style={{ paddingTop: '1.1rem' }}>Visit Type</Item>
                      </Grid>
                      <Grid item xs={7} className="text-left">
                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                          <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={visitType}
                            displayEmpty
                            onChange={handleVisitTypeChange}
                          >
                            <MenuItem value="">
                              <em>Select</em>
                            </MenuItem>
                            <MenuItem value="ipd">IPD</MenuItem>
                            <MenuItem value="opd">OPD</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                  </Grid>
                  {visitType == "ipd" ?
                    <Grid container className="mt2" spacing={3}>
                      <Grid item xs={5} className="text-right">
                        <Item style={{ paddingTop: '1.1rem' }}>Admitted Date</Item>
                      </Grid>
                      <Grid item xs={7} className="text-left">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label="Choose a Date"
                            value={admittedDate}
                            disableFuture
                            inputFormat="dd/MM/yyyy"
                            onChange={async (newValue) => {
                              setAdmittedDate(newValue);
                            } }
                            renderInput={(params) => <TextField sx={{ m: 1, width: 200 }} size="small" {...params} />} />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                    : 
                    <Grid container className="mt2" spacing={3}>
                      <Grid item xs={5} className="text-right">
                        <Item style={{ paddingTop: '1.1rem' }}>Arrived Date</Item>
                      </Grid>
                      <Grid item xs={7} className="text-left">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label="Choose a Date"
                            value={arrivedDate}
                            disableFuture
                            inputFormat="dd/MM/yyyy"
                            onChange={async (newValue) => {
                              setArrivedDate(newValue);
                            } }
                            renderInput={(params) => <TextField sx={{ m: 1, width: 200 }} size="small" {...params} />} />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                  }
                    </>
              : '' }

              {onHoldOptions ?
                <>
                  <Grid container className="mt2" spacing={3}>
                    <Grid item xs={5} className="text-right">
                      <Item style={{ paddingTop: '1.1rem' }}>Acknowledged Admission date</Item>
                    </Grid>
                    <Grid item xs={7} className="text-left">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          value={ackAdmisnDate}
                          disableFuture
                          inputFormat="dd/MM/yyyy"
                          onChange={async (newValue) => {
                            setAckAdmisnDate(newValue);
                          } }
                          renderInput={(params) => <TextField sx={{ m: 1, width: 200 }} size="small" {...params} />} />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                  
                  <Grid container className="mt2" spacing={3}>
                      <Grid item xs={5} className="text-right">
                        <Item style={{ paddingTop: '1.1rem' }}>Remarks</Item>
                      </Grid>
                      <Grid item xs={7} className="text-left">
                        <TextField
                          label="Remarks"
                          multiline
                          rows={4}
                          value={remarksValue}
                          onChange={handleRemarksChange}
                        />
                      </Grid>
                  </Grid>
                </>
                
              : '' }
              
              {refLostOptions ?
                <Grid container className="mt2" spacing={3}>
                  <Grid item xs={5} className="text-right">
                    <Item style={{ paddingTop: '1.1rem' }}>Reason</Item>
                  </Grid>
                  <Grid item xs={7} className="text-left">
                    <TextField
                        label="Reason"
                        multiline
                        rows={4}
                        value={reasonValue}
                        onChange={handleReasonChange}
                      />
                </Grid>
              </Grid>
              : '' }
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDialogClose}>Cancel</Button>
                <Button onClick={handleDialogSubmit}>Submit</Button>
              </DialogActions>
        </Dialog>

        <table className="data-grid-table">
          <thead style={{ position: 'sticky', top: '0px' }}>
            <tr>
              {" "}
              {DRE_PATIENT_DATA_HEADER_REFFERED.map((d, i) => (
                <th key={i}>
                  <span></span>
                  {d}
                </th>
              ))}{" "}
            </tr>
          </thead>
          <tbody>
            {patientArrayM.length > 0 ? (
              patientArrayM.map((d, i) => {
                return (
                  <tr key={i}>
                    {/* <td>{d.dr_etrade_partner_map ?? (d.dre_trade_partner_map.medunited_user.first_name + "" + d.dre_trade_partner_map.medunited_user.last_name)}</td> */}
                    <td>
                      {d?.dre_trade_partner_map?.medunited_user?.first_name +
                        " " +
                        d?.dre_trade_partner_map?.medunited_user?.last_name}
                    </td>
                    <td>{d?.dre_trade_partner_map?.medunited_user?.mobile_number}</td>
                    <td>{d.patient_name}</td>
                    <td>{d.mobile_number}</td>

                    <td>
                      {d.patient_status}&nbsp;
                      {userRoleVal === 'doctorReferralExecutive' ?
                      <>
                        <img className="" src={editIcon} style={{cursor: "pointer"}}
                            onClick={() => {
                            setSelPatient(d.id.toString());
                            setAdmittedOptions(false);
                            setOnHoldOptions(false);
                            setRefLostOptions(false);
                            handleClickDialogOpen();
                            setDialogTitleStr(d.patient_name);
                          }}
                        />
                      </>
                    : ''}
                    </td>
                    <td>{setDateFormat(d.created_at)}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td className="text-center" colSpan={7}>
                  No Patient Details available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <Pagination
        pageNo={page}
        totalPages={totalPages}
        previousPage={previousPage}
        nextPage={nextPage}
        rowPerPageChange={rowPerPageChange} />
    </>
  );
};
export default PatientInfoReferred;

