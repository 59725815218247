import { makeStyles } from "@mui/styles";
import moment from "moment";
import React, { useEffect, useState, useRef } from "react";
import Pagination from "../../components/pagination";
import HttpService from "../../services/HttpService";
import { GRADIENTIMAGECOLOR, PATIENT_DATA_HEADER_ONHOLD } from "../../utils/constants";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Grid, TextField, Snackbar } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Paper from '@mui/material/Paper';
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import KeycloakService from "../../services/KeycloakService";
import { VERSION } from "../../constants";
import editIcon from '../../assets/images/edit.svg';
import { PatientDataManager } from "../../types/Module_Type";

 const useStyles = makeStyles(() => ({
    snackbarStyleViaContentProps: {
      backgroundImage: GRADIENTIMAGECOLOR,
    },
  }));
const _axios = HttpService.getAxiosClient();
const userRoleVal = localStorage.getItem("userRole");

type Payload = {
    intent: string;
    state: {
      limit: number;
      offset: number;
      patientStatus: string;
      startDate: string,
      endDate: string,
      dreId?: string;
      clusterManagerId?: string;
      managerId?: string;
      agmId?: string;
      salesHeadId?: string;
      financeId?: string;
    };
  };


const PatientInfoOnHold = ({ 
    selectedVisitType,
    filteredData,
    filterApplied
  } : {
    selectedVisitType: string;
    filteredData: PatientDataManager;
    filterApplied: boolean
  }) => {    const classes = useStyles();
    const [patientArrayM, setPatientArrayM] = React.useState<PatientDataManager[]>([]);
    const [page, setPage] = React.useState(1);
    const [rowPageSize, setRowPageSize] = React.useState(10);
    const [totalPages, setTotalPages] = React.useState(Number);
    const [offsetVal, setOffsetVal] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [openDialog, setOpenDialog] = React.useState(false);

    const [patientStatus, setPatientStatus] = React.useState('');
    const [admittedOptions, setAdmittedOptions] = React.useState(false);
    const [refLostOptions, setRefLostOptions] = React.useState(false);

    const [patientUHIDValue, setPatientUHIDValue] = useState<string>('');
    const [reasonValue, setReasonValue] = useState<string>('');
    const [selPatient, setSelPatient] = React.useState('');

    const [visitType, setVisitType] = React.useState('');

    const [admittedDate, setAdmittedDate] = React.useState<Date | null>(null);
    const [arrivedDate, setArrivedDate] = React.useState<Date | null>(null);

    const [dialogTitleStr, setDialogTitleStr] = React.useState('');

    useEffect(() => {
        getInitialPaitentList();
    }, [page, rowPageSize, selectedVisitType, filteredData, filterApplied]);

    const getInitialPaitentList = async () => {
        try {
            setPatientArrayM([]);
            if(filterApplied){
                if(filteredData && filteredData?.patient_thop_map?.length > 0){
                    console.log("Filter applied and returned with response check");
                    setPatientArrayM(filteredData.patient_thop_map);
                    setTotalPages(Math.ceil(filteredData?.patient_thop_map_aggregate?.aggregate.count / rowPageSize));
                    setOffsetVal((rowPageSize * page) - rowPageSize);
                } else {
                    // Render message indicating no data found for the applied filter
                    setPatientArrayM([]);
                    setTotalPages(1);
                }
            } else {
                let intentStr = userRoleVal === 'doctorReferralExecutive' ? 'getRefferedAndReferralLostPatientsForDreByDate'
                  : userRoleVal === 'clusterManager' ? 'getAllReferredAndReferralLostPatientForClusterManager'
                  : userRoleVal === 'manager' ? 'getAllReferredAndReferralLostPatientsForManager'
                  : userRoleVal === 'assistantGeneralManager' ? 'getAllReferredAndReferralLostPatientsForAssistantGeneralManager'
                  : userRoleVal === 'salesHead' ? 'getAllReferredAndReferralLostPatientForSalesHead'
                  : '';

                const onHoldPatientsPayload:Payload = {
                    intent: intentStr,
                    state: {
                        patientStatus: "onhold",
                        limit: rowPageSize,
                        offset: offsetVal,
                        // startDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
                        startDate: '2023-01-01',
                        endDate: moment().format('YYYY-MM-DD'),
                    },
                }

                if (userRoleVal === 'doctorReferralExecutive') {
                    onHoldPatientsPayload.state.dreId = KeycloakService.getSub();
                } else if (userRoleVal === 'clusterManager') {
                    onHoldPatientsPayload.state.clusterManagerId = KeycloakService.getSub();
                } else if (userRoleVal === 'manager') {
                    onHoldPatientsPayload.state.managerId = KeycloakService.getSub();
                } else if (userRoleVal === 'assistantGeneralManager') {
                    onHoldPatientsPayload.state.agmId = KeycloakService.getSub();
                } else if (userRoleVal === 'salesHead') {
                    onHoldPatientsPayload.state.salesHeadId = KeycloakService.getSub();
                }

                const onHoldPatientsResp = await _axios.post(
                `${process.env.REACT_APP_MAIN_SERVER_URL}${process.env.REACT_APP_PROJECT_ID}`,onHoldPatientsPayload,
                    {
                        headers: {
                        version: VERSION,
                        },
                    }
                );
                
                const onHoldPatientsRespData = onHoldPatientsResp.data;

                if (
                    onHoldPatientsRespData &&
                    onHoldPatientsRespData.referred_patient_details.length != 0
                ) {
                    const sortedOnHoldPatientsArray = onHoldPatientsRespData.referred_patient_details.sort((a: { patient_admission_date: string }, b: { patient_admission_date: string }) => {
                        const dateA = new Date(a.patient_admission_date);
                        const dateB = new Date(b.patient_admission_date);
                      
                        return dateB.getTime() - dateA.getTime();
                    });
                      
                    setPatientArrayM(sortedOnHoldPatientsArray);
                    setTotalPages(
                        Math.ceil(onHoldPatientsRespData.referred_patient_details_aggregate.aggregate
                        .count / rowPageSize)
                    );
                    setOffsetVal((rowPageSize * page) - rowPageSize);
                } else {
                    setPatientArrayM([]);
                    setTotalPages(1);
                }
                
                if (onHoldPatientsRespData.referred_patient_details_aggregate.aggregate
                    .count === 0) {
                    setPatientArrayM([]);
                    setTotalPages(1);
                }
            }
        }
        catch (e: any) {
            console.log(e);
            setMessage('getInitialPaitentList' + e.message);
            setOpen(true);
        }
    }

    const setPatientStatusChange = (event: SelectChangeEvent<string>) => {
        setPatientStatus(event.target.value);
    
        setAdmittedOptions(false);
        setRefLostOptions(false);
        if(event.target.value === "admitted") {
          setAdmittedOptions(true);
        } else if(event.target.value === "referralLost"){
          setRefLostOptions(true);
        }
    };

    const handlePatientUHIDChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPatientUHIDValue(event.target.value);
    };

    const handleReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setReasonValue(event.target.value);
    };

    const previousPage = () => {
        let previousPageNo = page - 1;
        setPage(previousPageNo);
        setOffsetVal(offsetVal - rowPageSize);
    }
    const nextPage = () => {
        let nextPageNo = page + 1;
        setPage(nextPageNo);
        setOffsetVal(offsetVal + rowPageSize);
    }
    const rowPerPageChange = (e: any) => {
        let val = e.target.value;
        setRowPageSize(val);
    }

    const setDateFormat = (date:String) => {
        if(date != null && date != ""){
            let dateWithOutString = date.replace(/"/gi, "");
            dateWithOutString = moment(dateWithOutString).format('DD-MMM-YYYY / hh:mm')
            return dateWithOutString;
        } else {
            return '-';
        }
    }

    const onHoldPatientsTableContainerRef = useRef<HTMLDivElement | null>(null)
    useEffect(() => {
        const resizeHandler = () => {
            const container = onHoldPatientsTableContainerRef.current
            if (container) {
            const availableHeight: any =
                window.innerHeight - container.getBoundingClientRect().top
            container.style.maxHeight = `${availableHeight - 90}px`
            }
        }

        window.addEventListener('resize', resizeHandler)
        resizeHandler()

        return () => {
            window.removeEventListener('resize', resizeHandler)
        }
    }, [])

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        color: theme.palette.text.secondary,
        boxShadow: 'none'
    }));

    const handleVisitTypeChange = (event: SelectChangeEvent) => {
        setVisitType(event.target.value);
        setAdmittedDate(null);
        setArrivedDate(null);
        setPatientUHIDValue('');
    };

    const handleClickDialogOpen = () => {
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    const handleDialogSubmit = async () => {
        if(patientStatus == ""){
            setMessage('Please select any status for the patient');
            setOpen(true);
            return false;
          }
      
          let intentStr = "";
          let patientStatusStr = "";
          let resourceStr = "";
          let selDateVal = "";
      
          let payloadObj = {};
      
          if(patientStatus === "admitted") {
            resourceStr = visitType;
            let dateKeyStr = "";
      
            if(visitType == "ipd"){
              intentStr = "ipAdmitApi";
              patientStatusStr = "admitted";
              dateKeyStr = "admittedOn";
              selDateVal = admittedDate ? admittedDate.toISOString() : '';
            } else if(visitType == "opd"){
              intentStr = "createPatientOpArrivedRecord";
              patientStatusStr = "arrived";
              dateKeyStr = "arrivedOn";
              selDateVal = arrivedDate ? arrivedDate.toISOString() : '';
            }
      
            if(visitType == "ipd"){
      
              payloadObj = {
                intent: intentStr,
                "state":{
                  "updatedBy": localStorage.getItem("userName"),
                  "patientUhid": patientUHIDValue,
                  "visitType": visitType,
                  "patientStatus": patientStatusStr,
                  "admittedOn" : selDateVal,
                  "patientId": selPatient,
                  "resource": resourceStr,
                  "userRole": localStorage.getItem("userRole")
                }
              };
            } else if(visitType == "opd"){
              payloadObj = {
                intent: intentStr,
                "state":{
                  "updatedBy": localStorage.getItem("userName"),
                  "patientUhid": patientUHIDValue,
                  "visitType": visitType,
                  "patientStatus": patientStatusStr,
                  "arrivedOn" : selDateVal,
                  "patientId": selPatient,
                  "resource": resourceStr,
                  "userRole": localStorage.getItem("userRole")
                }
              };
            }
      
          } else if(patientStatus === "referralLost"){
            intentStr = "updatePatientReferralLostStatus";
            
            payloadObj = {
              intent: intentStr,
              "state":{
                "patientId": selPatient,
                "updatedBy": localStorage.getItem("userName"),
                "userRole": localStorage.getItem("userRole"),
                "patientStatus": patientStatus,
                "remarks": reasonValue,
              }
            };
          }
      
          const onHoldStatusChangeResp = await _axios.post(
            `${process.env.REACT_APP_MAIN_SERVER_URL}${process.env.REACT_APP_PROJECT_ID}`,
            payloadObj,
            {
              headers: {
                version: VERSION,
              },
            }
          );

        if(onHoldStatusChangeResp.status == 200 && onHoldStatusChangeResp.data.update_referred_patient_details_by_pk.length != 0){
            getInitialPaitentList();
            setMessage('Patient status for '+dialogTitleStr+' has been updated successfully');
            setOpen(true);
            setOpenDialog(false);
        } else {
            setMessage('Unable to update the patient '+dialogTitleStr+' status.');
            setOpen(true);
            return false;
        }

    };    


    return (
        <>
            <div className='tableWithHadle' ref={onHoldPatientsTableContainerRef}>
                <Snackbar
                    ContentProps={{
                    "aria-describedby": "message-id",
                    className: classes.snackbarStyleViaContentProps
                    }}
                    open={open}
                    autoHideDuration={3000}
                    message={message}
                    onClose={() => setOpen(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                />

                <Dialog open={openDialog} onClose={handleDialogClose}>
                    <DialogTitle>{dialogTitleStr + " - Status"}</DialogTitle>
                    <DialogContent>

                    <Grid container className="mt2" spacing={3}>
                        <Grid item xs={5} className="text-right">
                            <Item style={{ paddingTop: '1.1rem' }}>Patient Status</Item>
                        </Grid>
                        <Grid item xs={7} className="text-left">
                        <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
                            <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={patientStatus}
                            label="Select"
                            onChange={setPatientStatusChange}
                            >
                                <MenuItem value="">
                                    <em>Select</em>
                                </MenuItem>
                                <MenuItem value="admitted">Admitted</MenuItem>
                                <MenuItem value="referralLost">Referral Lost</MenuItem>
                            </Select>
                        </FormControl>
                        </Grid>
                    </Grid>

                    {admittedOptions ? 

                    <>
                    <Grid container className="mt2" spacing={3}>
                        <Grid item xs={5} className="text-right">
                            <Item style={{ paddingTop: '2rem' }}>Patient UHID</Item>
                        </Grid>
                        <Grid item xs={7} className="text-left" style={{ paddingLeft: "33px" }}>
                            <TextField
                            autoFocus
                            margin="dense"
                            label="Patient UHID"
                            type="text"
                            variant="standard"
                            value={patientUHIDValue}
                            onChange={handlePatientUHIDChange}
                            />
                        </Grid>
                    </Grid>
                        
                    <Grid container className="mt2" spacing={3}>
                        <Grid item xs={5} className="text-right">
                            <Item style={{ paddingTop: '1.1rem' }}>Visit Type</Item>
                        </Grid>
                        <Grid item xs={7} className="text-left">
                            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                <InputLabel id="demo-select-small-label">Visit Type</InputLabel>
                                <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                value={visitType}
                                label="Select"
                                onChange={handleVisitTypeChange}
                                >
                                <MenuItem value="">
                                    <em>Select</em>
                                </MenuItem>
                                <MenuItem value="ipd">IPD</MenuItem>
                                <MenuItem value="opd">OPD</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                        
                    {visitType == "ipd" ?
                        <Grid container className="mt2" spacing={3}>
                            <Grid item xs={5} className="text-right">
                                <Item style={{ paddingTop: '1.1rem' }}>Admitted Date</Item>
                            </Grid>
                            <Grid item xs={7} className="text-left">
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                    label="Choose a Date"
                                    value={admittedDate}
                                    disableFuture
                                    onChange={async (newValue) => {
                                        setAdmittedDate(newValue);
                                    } }
                                    renderInput={(params) => <TextField sx={{ m: 1, width: 200 }} size="small" {...params} />} />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>


                        : 
                        
                        <Grid container className="mt2" spacing={3}>
                            <Grid item xs={5} className="text-right">
                            <Item style={{ paddingTop: '1.1rem' }}>Arrived Date</Item>
                            </Grid>
                            <Grid item xs={7} className="text-left">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                label="Choose a Date"
                                value={arrivedDate}
                                disableFuture
                                onChange={async (newValue) => {
                                    setArrivedDate(newValue);
                                } }
                                renderInput={(params) => <TextField sx={{ m: 1, width: 200 }} size="small" {...params} />} />
                            </LocalizationProvider>
                            </Grid>
                        </Grid>
                        }
                        
                        </>

                    : '' }
                    
                    {refLostOptions ?
                    <Grid container className="mt2" spacing={3}>
                        <Grid item xs={5} className="text-right">
                        <Item style={{ paddingTop: '1.1rem' }}>Reason</Item>
                        </Grid>
                        <Grid item xs={7} className="text-left">
                        <TextField
                            label="Reason"
                            multiline
                            rows={4}
                            value={reasonValue}
                            onChange={handleReasonChange}
                            />
                    </Grid>
                    </Grid>
                    
                    : '' }

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDialogClose}>Cancel</Button>
                        <Button onClick={handleDialogSubmit}>Submit</Button>
                    </DialogActions>
                </Dialog>

                <table className='data-grid-table'>
                    <thead style={{ position: 'sticky', top: '0px' }}>
                        <tr> {PATIENT_DATA_HEADER_ONHOLD.map((d, i) => <th key={i}><span></span>{d}</th>)} </tr>
                    </thead>
                    <tbody>
                        {patientArrayM.length > 0 ?
                            patientArrayM.map((d, i) => (
                                <tr key={i}>
                                    <td>
                                    {d?.dre_trade_partner_map?.medunited_user?.first_name +
                                        " " +
                                        d?.dre_trade_partner_map?.medunited_user?.last_name}
                                    </td>
                                    <td>{d.dre_trade_partner_map?.medunited_user?.mobile_number}</td>
                                    <td>{d.patient_name}</td>
                                    <td>{d.mobile_number}</td>
                                    <td>{d.patient_status}&nbsp;
                                        {userRoleVal === 'doctorReferralExecutive' ?
                                            <>
                                                <img className="" src={editIcon} style={{cursor: "pointer"}}
                                                    onClick={() => {
                                                        handleClickDialogOpen();
                                                        setSelPatient(d.id.toString());
                                                        setDialogTitleStr(d.patient_name);
                                                }} />
                                            </>
                                        : ''}
                                    </td>
                                    <td>{setDateFormat(d.patient_admission_date)}</td>
                                    <td>{d.patient_reason}</td>
                                </tr>
                            )) :
                            <tr>
                                <td className='text-center' colSpan={9}>No Patient Details available</td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
            <Pagination
                pageNo={page}
                totalPages={totalPages}
                previousPage={previousPage}
                nextPage={nextPage}
                rowPerPageChange={rowPerPageChange}
            />
        </>
    );
}
export default PatientInfoOnHold;
