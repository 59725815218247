import React from "react";
import arrowLeft from "../assets/images/chevron_left.png";
import arrowRight from "../assets/images/chevron_right.png";
import './pagination.css';

const Pagination = (props: any) => {
    return (
        <div className="pagination text-right">
            <span>
                Rows per page :
                <select
                    className="rowsperpage"
                    onChange={props.rowPerPageChange}
                >
                    {[10, 20, 30, 40, 50].map((pageSize) => (
                        <option
                            key={pageSize}
                            value={pageSize}
                        >
                            {pageSize}
                        </option>
                    ))}
                </select>
            </span>
            <span>
                Page: {props.pageNo} of {props.totalPages}
            </span>
            <button
                id="previous_page_btn"
                className={props.pageNo > 1 ? "ebable" : "disablePagBtn"}
                onClick={() => props.previousPage()}
            >
                <img src={arrowLeft} alt="arrow-left" />
            </button>
            <button
                id="next_page_btn"
                className={
                    props.pageNo < props.totalPages
                        ? "ebable"
                        : "disablePagBtn"
                }
                onClick={() => props.nextPage()}
            >
                <img src={arrowRight} alt="arrow-right" />
            </button>
        </div>
    );
}

export default Pagination;