import { makeStyles } from "@mui/styles";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import Pagination from "../../components/pagination";
import HttpService from "../../services/HttpService";
import { GRADIENTIMAGECOLOR } from "../../utils/constants";
import KeycloakService from "../../services/KeycloakService";
import { VERSION } from "../../constants";
import editIcon from '../../assets/images/edit.svg';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Grid, TextField, Snackbar } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Paper from '@mui/material/Paper';
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { PatientDataManager } from "../../types/Module_Type";
import commentIcon from '../../assets/images/comment.svg';
import editCommentIcon from '../../assets/images/edit_comment.svg';


 const useStyles = makeStyles(() => ({
  snackbarStyleViaContentProps: {
    backgroundImage: GRADIENTIMAGECOLOR,
  },
}));

const _axios = HttpService.getAxiosClient();
const userRoleVal = localStorage.getItem("userRole");

type Payload = {
    intent: string;
    state: {
      limit: number;
      offset: number;
      patientStatus: string;
      startDate: string,
      endDate: string,
      visitType: string,
      isConvertedToIp: string,
      dreId?: string;
      clusterManagerId?: string;
      managerId?: string;
      agmId?: string;
      salesHeadId?: string;
      financeId?: string;
    };
};

type user_comments = {
    id: number;
    fk_thop_id: number;
    comments?: string;
    created_at?: string;
    updated_at?: string;
}


const PatientInfoAdmitted = ({ 
  selectedVisitType,
  filteredData,
  filterApplied
} : {
  selectedVisitType: string;
  filteredData: PatientDataManager;
  filterApplied: boolean
}) => {
  const classes = useStyles();
    const [patientArrayM, setPatientArrayM] = React.useState<PatientDataManager[]>([]);
    const [page, setPage] = React.useState(1);
    const [rowPageSize, setRowPageSize] = React.useState(10);
    const [totalPages, setTotalPages] = React.useState(Number);
    const [offsetVal, setOffsetVal] = React.useState(0);

    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [selPatient, setSelPatient] = React.useState('');
    const [selfkPatient, setfkSelPatient] = React.useState('');

    const [patientUhid, setPatientUhid] = React.useState('');
    const [tradePartnerType, setTradePartnerType] = React.useState('');
    const [fkVisitId, setfkVisitId] = React.useState('');


    const [dischargeDate, setDischargeDate] = React.useState<Date | null>(null);
    const [patientStatus, setPatientStatus] = React.useState('');
    const [dischargedOptions, setDischargedOptions] = React.useState(false);

    const [dialogTitleStr, setDialogTitleStr] = React.useState('');

    const [openDialog, setOpenDialog] = React.useState(false);

    const [openCommentDialog, setOpenCommentDialog] = React.useState(false);
    const [commentValue, setCommentValue] = useState<string>('');
    const [commentTxtFldDisabled, setCommentTxtFldDisabled] = useState(true);
    const [toggleAddCommentBlock, setToggleAddCommentBlock] = useState(false);
    const [toggleCommentsTableBlock, setToggleCommentsTableBlock] = useState(true);

    const [commentsArray, setCommentsArray] = React.useState<user_comments[]>([]);

    const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper');

    useEffect(() => {
        getInitialPaitentList();
    }, [page, rowPageSize, selectedVisitType, filteredData, filterApplied]);

    const getInitialPaitentList = async () => {
        try {
            setPatientArrayM([]);
            if(filterApplied){
                if(filteredData && filteredData?.patient_thop_map?.length > 0){
                    setPatientArrayM(filteredData.patient_thop_map);
                    setTotalPages(Math.ceil(filteredData?.patient_thop_map_aggregate?.aggregate.count / rowPageSize));
                    setOffsetVal((rowPageSize * page) - rowPageSize);
                } else {
                    // Render message indicating no data found for the applied filter
                    setPatientArrayM([]); 
                    setTotalPages(1);
                }
            } else {
                // const today = moment();
                let intentStr = userRoleVal === 'doctorReferralExecutive' ? 'getPatientDetailsFromThopDataForDre'
                  : userRoleVal === 'clusterManager' ? 'getPatientDetailsFromThopForClusterManager'
                  : userRoleVal === 'manager' ? 'getPatientThopDetailsForManager'
                  : userRoleVal === 'assistantGeneralManager' ? 'getPatientDetailsFromThopForAssistantGeneralManager'
                  : userRoleVal === 'salesHead' ? 'getPatientDetailsForSalesHeadBasedFromThopData'
                  : '';

                const admittedPatientsPayload:Payload = {
                    intent: intentStr,
                    state: {
                        patientStatus: selectedVisitType == "opd" ? "arrived" : "admitted",
                        visitType: selectedVisitType,
                        isConvertedToIp: "no",
                        limit: rowPageSize,
                        offset: offsetVal,
                        /* need to fetch records based on date, for last 30 days */
                        // startDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
                        startDate: '2023-01-01',
                        endDate: moment().add(1, 'days').format('YYYY-MM-DD'),
                    },
                }

                if (userRoleVal === 'doctorReferralExecutive') {
                    admittedPatientsPayload.state.dreId = KeycloakService.getSub();
                } else if (userRoleVal === 'clusterManager') {
                    admittedPatientsPayload.state.clusterManagerId = KeycloakService.getSub();
                } else if (userRoleVal === 'manager') {
                    admittedPatientsPayload.state.managerId = KeycloakService.getSub();
                } else if (userRoleVal === 'assistantGeneralManager') {
                    admittedPatientsPayload.state.agmId = KeycloakService.getSub();
                } else if (userRoleVal === 'salesHead') {
                    admittedPatientsPayload.state.salesHeadId = KeycloakService.getSub();
                }

                const admittedPatientsResp = await _axios.post(
                    `${process.env.REACT_APP_MAIN_SERVER_URL}${process.env.REACT_APP_PROJECT_ID}`,
                    admittedPatientsPayload,
                    {
                    headers: {
                        version: VERSION,
                    },
                    }
                );

              const admittedPatientsRespData = admittedPatientsResp.data;

              if (
                admittedPatientsRespData &&
                admittedPatientsRespData.patient_thop_map.length != 0
              ) {
                let sortedAdmittedPatientsArray: any = '';
                if(selectedVisitType == "opd"){
                    sortedAdmittedPatientsArray = admittedPatientsRespData.patient_thop_map.sort((a: { arrived_on: string }, b: { arrived_on: string }) => {
                        const dateA = new Date(a.arrived_on);
                        const dateB = new Date(b.arrived_on);
                      
                        return dateB.getTime() - dateA.getTime();
                    });
                } else if(selectedVisitType == "ipd"){
                    sortedAdmittedPatientsArray = admittedPatientsRespData.patient_thop_map.sort((a: { admitted_on: string }, b: { admitted_on: string }) => {
                        const dateA = new Date(a.admitted_on);
                        const dateB = new Date(b.admitted_on);
                      
                        return dateB.getTime() - dateA.getTime();
                    });
                }
                setPatientArrayM(sortedAdmittedPatientsArray)
                setTotalPages(
                    Math.ceil(admittedPatientsRespData.patient_thop_map_aggregate.aggregate
                    .count / rowPageSize)
                );

                setOffsetVal((rowPageSize * page) - rowPageSize);
              } else {
                setPatientArrayM([]);
                setTotalPages(1);
              }
            }
        }
        catch (e: any) {
            console.log(e);
            setMessage('getInitialPaitentList' + e.message);
            setOpen(true);
        }
    }

    const previousPage = () => {
        let previousPageNo = page - 1;
        setPage(previousPageNo);
        setOffsetVal(offsetVal - rowPageSize);
    }
    
    const nextPage = () => {
        let nextPageNo = page + 1;
        setPage(nextPageNo);
        setOffsetVal(offsetVal + rowPageSize);
    }
    
    const rowPerPageChange = (e: any) => {
        let val = e.target.value;
        setRowPageSize(val);
    }

    const setDateFormat = (date:String) => {
        if(date != null && date != ""){
            let dateWithOutString = date.replace(/"/gi, "");
            dateWithOutString = moment(dateWithOutString).format('DD-MMM-YYYY / hh:mm')
            return dateWithOutString;
        } else {
            return '-';
        }
    }

    const setDateFormat1 = (date:String) => {
        if(date != null && date != ""){
            let dateWithOutString = date.replace(/"/gi, "");
            dateWithOutString = moment(dateWithOutString).subtract(1, 'day').format('DD-MMM-YYYY / hh:mm')
            return dateWithOutString;
        } else {
            return '-';
        }    
    }

    const setPatientStatusChange = (event: SelectChangeEvent<string>) => {
        setPatientStatus(event.target.value);

        setDischargeDate(null);
        if(selectedVisitType == "opd" ? event.target.value === "consulted" : event.target.value === "discharged") {
          setDischargedOptions(true);
        } else {
            setDischargedOptions(false);
        }
    };

    const handleClickCommentDialogOpen = () => {
        setCommentTxtFldDisabled(true);

        setToggleAddCommentBlock(false);
        setToggleCommentsTableBlock(true);
        setOpenCommentDialog(true);
    };

    const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCommentValue(event.target.value);
    };

    const handleCommentDialogClose = () => {
        setOpenCommentDialog(false);
    };

    const handleCommentDialogSubmit = async () => {
        try{
            if(commentValue == ""){
                setMessage('Please enter something to save');
                setOpen(true);
                return false;
            }

            const saveCommentPayloadObj:any = {
                intent: "insertPatientComments",
                state: {
                    fkThopId : selPatient,
                    comments : commentValue
                }
            }

            const saveCommentResp = await _axios.post(
                `${process.env.REACT_APP_MAIN_SERVER_URL}${process.env.REACT_APP_PROJECT_ID}`,
                saveCommentPayloadObj,
                {
                    headers: {
                        version: VERSION,
                    },
                }
            )

            if(saveCommentResp.status == 200 && saveCommentResp.data.insert_user_comments_one.length != 0){
                getInitialPaitentList();
                setMessage('Comment for Patient "'+dialogTitleStr+'" has been saved successfully');
                setOpen(true);
                setOpenCommentDialog(false);
            } else {
                setOpenCommentDialog(false);
                setMessage('Unable to save comment for patient "'+dialogTitleStr+'" .');
                setOpen(true);
                return false;
            }
        }
        catch (e: any) {
            console.log(e);
            setOpenCommentDialog(false);
            setMessage('Unable to save comment for patient '+dialogTitleStr+' status. Reason : ' + e.response.data.message);
            setOpen(true);
            return false;
        }
    }    

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        color: theme.palette.text.secondary,
        boxShadow: 'none'
    }));

    const admittedPatientsTableContainerRef = useRef<HTMLDivElement | null>(null)
    
    useEffect(() => {
        const resizeHandler = () => {
            const container = admittedPatientsTableContainerRef.current
            if (container) {
            const availableHeight: any =
                window.innerHeight - container.getBoundingClientRect().top
            container.style.maxHeight = `${availableHeight - 90}px`
            }
        }

        window.addEventListener('resize', resizeHandler)
        resizeHandler()

        return () => {
            window.removeEventListener('resize', resizeHandler)
        }
    }, [])

    // const commentsTableContainerRef = useRef<HTMLDivElement | null>(null)

    // useEffect(() => {
    //     const commentResizeHandler = () => {
    //         const container = commentsTableContainerRef.current
    //         if (container) {
    //         const availableHeight: any =
    //             window.innerHeight - container.getBoundingClientRect().top
    //         container.style.maxHeight = `${availableHeight - 90}px`
    //         }
    //     }

    //     window.addEventListener('resize', commentResizeHandler)
    //     commentResizeHandler()

    //     return () => {
    //         window.removeEventListener('resize', commentResizeHandler)
    //     }
    // }, [])

    const handleClickDialogOpen = () => {
        setPatientStatus('');
        setDischargedOptions(false);
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    const handleDialogSubmit = async () => {
        try{
            if(patientStatus == ""){
                setMessage('Please select status for the patient');
                setOpen(true);
                return false;
            }

            let validationStr = selectedVisitType == "opd" ? "consulted" : "discharged"

            if(dischargeDate == null){
                setMessage('Please enter ' + validationStr + ' date for the patient');
                setOpen(true);
                return false;
            }

            let payloadObj = {
                "userRole": userRoleVal,
                "patientStatus": selectedVisitType == "opd" ? "consulted" : "discharged",
                "updatedBy": localStorage.getItem("userName"),
                "visitType": selectedVisitType,
                "dischargedOn": dischargeDate,
                "patientThopId": selPatient,
                "fkPatientId": selfkPatient,
                "fkVisitId": fkVisitId,
                "tradePartnerType": tradePartnerType,
                "patientUhid": patientUhid
            }

            const refferedStatusChangeResp = await _axios.post(
                `https://dev.medunited-rms.techsophy.com/medunited-rms/patient/discharge/payment`,
                payloadObj,
                {
                headers: {
                    version: VERSION,
                },
                }
            )

            if(refferedStatusChangeResp.status == 200 && refferedStatusChangeResp.data.length != 0){
                getInitialPaitentList();
                setMessage('Patient status for '+dialogTitleStr+' has been updated successfully');
                setOpen(true);
                setOpenDialog(false);
            } else {
                setMessage('Unable to update the patient '+dialogTitleStr+' status.');
                setOpen(true);
                return false;
            }
        }
        catch (e: any) {
            console.log(e);
            setOpenDialog(false);
            setMessage('Unable to update the patient '+dialogTitleStr+' status. Reason : ' + e.response.data.message);
            setOpen(true);
            return false;
        }
    };    

    return (
        <>
            <div className='tableWithHadle' ref={admittedPatientsTableContainerRef}>
                <Snackbar
                    ContentProps={{
                        "aria-describedby": "message-id",
                        className: classes.snackbarStyleViaContentProps
                    }}
                    open={open}
                    autoHideDuration={3000}
                    message={message}
                    onClose={() => setOpen(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                />

                <Dialog open={openCommentDialog} onClose={handleCommentDialogClose} fullWidth={ true } maxWidth={"sm"}>
                    <DialogTitle>{dialogTitleStr + "("+patientUhid+") - Comment"}</DialogTitle>
                    <DialogContent dividers={scroll === 'paper'} style={{height:'80vh'}}>
                        {toggleCommentsTableBlock ?
                            <Grid container className="mt2" spacing={3}>
                                <div className="edit-comment">
                                    <img src={editCommentIcon} style={{cursor: "pointer", float: "right"}}
                                        onClick={() => {
                                            setCommentTxtFldDisabled(false);
                                            setToggleCommentsTableBlock(false);
                                            setToggleAddCommentBlock(true);
                                        }}
                                    />
                                </div>
                                <div className="comments_table_block">
                                    {commentsArray.length > 0 ?
                                        commentsArray.map((c, i) => (
                                        <div className="comment_row" key={i}>
                                            <span className="comment_createdat">{c.created_at ? setDateFormat(c.created_at) : "-"}</span>
                                            <span style={{ display: 'block' }}>{c.comments ? c.comments : "-"}</span>
                                        </div>
                                    )) :
                                       <div className="text-center">No Comments available</div>
                                    }
                                    {/* <table className='data-grid-table'>
                                        <thead style={{ position: 'sticky', top: '0px' }}>
                                            <tr> {['Comments', 'Created At'].map((d, i) => <th key={i}><span></span>{d}</th>)} </tr>
                                        </thead>
                                        <tbody>
                                            {commentsArray.length > 0 ?
                                                commentsArray.map((c, i) => (
                                                <tr key={i}>
                                                    <td>{c.comments ? c.comments : "-"}</td>
                                                    <td>{c.created_at ? setDateFormat(c.created_at) : "-"}</td>
                                                </tr>    
                                            )) :
                                                <tr>
                                                    <td className='text-center' colSpan={2}>No Comments available</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table> */}
                                </div>
                            </Grid>
                        : null}

                        {toggleAddCommentBlock ?
                            <Grid container spacing={3}>
                                <Grid item xs={5} className="text-center">
                                    <Item style={{ paddingTop: '1.1rem' }}>Comment</Item>
                                </Grid>
                                <Grid item xs={7} className="text-center">
                                    <TextField
                                        multiline
                                        fullWidth
                                        rows={4}
                                        value={commentValue}
                                        disabled={commentTxtFldDisabled}
                                        onChange={handleCommentChange}
                                        className={commentTxtFldDisabled ? "disabledTextField" : ""}
                                    />
                                </Grid>
                            </Grid>
                        : null}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCommentDialogClose}>Cancel</Button>
                        <Button onClick={handleCommentDialogSubmit}>Submit</Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={openDialog} onClose={handleDialogClose}>
                    <DialogTitle>{dialogTitleStr + " - Status"}</DialogTitle>
                    <DialogContent>      

                    <Grid container className="mt2" spacing={3}>
                        <Grid item xs={5} className="text-right">
                        <Item style={{ paddingTop: '1.1rem' }}>Patient Status</Item>
                        </Grid>
                        <Grid item xs={7} className="text-left">
                            <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
                                <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                value={patientStatus}
                                label="Select"
                                onChange={setPatientStatusChange}
                                >
                                <MenuItem value="">
                                    <em>Select</em>
                                </MenuItem>
                                {selectedVisitType == "opd" ?
                                    <MenuItem value="consulted">Consulted</MenuItem>
                                :   <MenuItem value="discharged">Discharged</MenuItem>
                                }
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>

                    {dischargedOptions ?

                        <Grid container className="mt2" spacing={3}>
                            <Grid item xs={5} className="text-right">
                                <Item style={{ paddingTop: '1.1rem' }}>{selectedVisitType == "opd" ? "Consulted date" : "Discharge date"}</Item>
                            </Grid>
                            <Grid item xs={7} className="text-left">
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    value={dischargeDate}
                                    disableFuture
                                    inputFormat="dd/MM/yyyy"
                                    onChange={async (newValue) => {
                                    setDischargeDate(newValue);
                                    } }
                                    renderInput={(params) => <TextField sx={{ m: 1, width: 200 }} size="small" {...params} />} />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>

                    : '' }

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDialogClose}>Cancel</Button>
                        <Button onClick={handleDialogSubmit}>Submit</Button>
                    </DialogActions>
                </Dialog>

                <table className='data-grid-table'>
                    <thead style={{ position: 'sticky', top: '0px' }}>
                        <tr> {['Patient UHID','Referrer Name','Referrer Mobile','Patient Name','Patient Number','Status', 'Referred Date', selectedVisitType == "opd" ? 'Arrived on' : 'Admitted On', 'Comments'].map((d, i) => <th key={i}><span></span>{d}</th>)} </tr>
                    </thead>
                    <tbody>
                        {patientArrayM.length > 0 ?
                            patientArrayM.map((d, i) => (
                                <tr key={i}>
                                    <td>{d.patient_uhid ? d.patient_uhid : "-"}</td>
                                    <td>
                                    {d?.referred_patient_detail?.dre_trade_partner_map?.medunited_user?.first_name +
                                        " " +
                                        d?.referred_patient_detail?.dre_trade_partner_map?.medunited_user?.last_name}
                                    </td>
                                    <td>{d.referred_patient_detail?.dre_trade_partner_map?.medunited_user?.mobile_number}</td>
                                    <td>{d.referred_patient_detail?.patient_name}</td>
                                    <td>{d.referred_patient_detail?.mobile_number}</td>
                                    <td>{d.patient_status}&nbsp;
                                        {userRoleVal === 'doctorReferralExecutive' ?
                                        <>
                                            <img className="" src={editIcon} style={{cursor: "pointer"}}
                                                onClick={() => {
                                                    setSelPatient(d.id.toString())
                                                    setfkSelPatient(d.fk_patient_id.toString());
                                                    setPatientUhid(d.patient_uhid);
                                                    setTradePartnerType(d?.referred_patient_detail?.dre_trade_partner_map?.medunited_user?.doctor_type);
                                                    setfkVisitId(d.visit_id);
                                                    handleClickDialogOpen();
                                                    setDialogTitleStr(d.referred_patient_detail?.patient_name);
                                            }} />
                                        </>
                                        : ''}    
                                    </td>
                                    <td>{setDateFormat(d.referred_patient_detail?.created_at)}</td>
                                    <td>{setDateFormat1(selectedVisitType == "opd" ? d.arrived_on : d.admitted_on)}</td>
                                    <td className='text-center'>
                                        <>
                                            <img className="" src={commentIcon} style={{cursor: "pointer"}}
                                                onClick={() => {
                                                    let sortedCommentsArray: any = '';
                                                    sortedCommentsArray = d.patient_thop_map_user_comments.sort((p: { created_at: string }, q: { created_at: string }) => {
                                                        const dateA = new Date(p.created_at);
                                                        const dateB = new Date(q.created_at);
                                                    
                                                        return dateB.getTime() - dateA.getTime();
                                                    });
                                                    setCommentsArray(sortedCommentsArray);
                                                    handleClickCommentDialogOpen();
                                                    setSelPatient(d.id.toString());
                                                    setPatientUhid(d.patient_uhid);
                                                    setDialogTitleStr(d.referred_patient_detail?.patient_name);
                                                }}
                                            />
                                        </>
                                    </td>
                                </tr>
                            )) :
                            <tr>
                                <td className='text-center' colSpan={8}>No Patient Details available</td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
            <Pagination
                pageNo={page}
                totalPages={totalPages}
                previousPage={previousPage}
                nextPage={nextPage}
                rowPerPageChange={rowPerPageChange}
            />
        </>
    );
}
export default PatientInfoAdmitted;

