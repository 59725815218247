import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { Button, Snackbar, TextField } from "@mui/material";
import "./../pages.css";
import HttpService from "../../services/HttpService";
import { VERSION } from "../../constants";
import KeycloakService from "../../services/KeycloakService";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { RenderMenuItemsProps } from "../../types/Module_Type";
import axios from "axios";

const _axios = HttpService.getAxiosClient();


const RenderMenuItems: React.FC<RenderMenuItemsProps> = ({
    selectedVisitType,
    activeTabVal,
    activeTabType,
    handleClose,
    setFilteredData,
    setFilterApplied,
    setMessage,
    setAppliedFilters,
    referrerList
  }) => {
  
    type Payload = {
      intent: string;
      state: {
        dreId: string;
        visitType: string;
        patientStatus: string;
        limit: number;
        offset: number;
        isConvertedToIp: string;
        [key: string]: string | number | Date;
      };
    };
  
    type dataArray = {
        medunited_user: any;
        first_name: string;
        last_name: string;
        label: string;
        value: string;
        year: string;
      };

    const [filterFromDateVal, setFilterFromDate] = React.useState<Date | null>(null);
    const [filterToDateVal, setFilterToDate] = React.useState<Date | null>(null);
    const [filterPatientName, setFilterPatientName] = useState<string>('');
    // const [referrerList, setReferrerList] = React.useState<dataArray[]>([]);
    const [selectedReferrerValue, setSelectedReferrerValue] = React.useState<string>('');
    const [selectedReferrerId, setSelectedReferrerId] = React.useState<string>('');
  
    function generatePayload<T extends { [key: string]: string | number | Date }>(
      intent: string,
      params: T
    ): Payload {
      return {
        intent,
        state: {
          ...commonPayload,
          ...params,
        },
      };
    }
  
    const commonPayload = {
      dreId: KeycloakService.getSub() || '',
      visitType: selectedVisitType,
      patientStatus: activeTabType,
      limit: 10,
      offset: 0,
      isConvertedToIp: 'no',
    };
    
    const handleFilterPatientNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setFilterPatientName(event.target.value);
    };
  
    const handleReferrerChange = (event: SelectChangeEvent<string>) => {
      setSelectedReferrerValue(event.target.value);
      setSelectedReferrerId(event.target.value);
    };
  
    const setDateFormat = (date: String) => {
      if(date != null && date != ""){
        let dateWithOutString = date.replace(/"/gi, "");
        dateWithOutString = moment(dateWithOutString).format("DD-MMM-YYYY / hh:mm");
        return dateWithOutString;
      } else {
        return '-';
      }
    };
  
  const referrerData: { fullName: string; ReferrerNumber:string }[] = [];
  if(referrerList){
    for (let i = 0; i < referrerList.length; i++) {
      const referrer =     referrerList[i];
      const ReferrerNumber=`${referrer.medunited_user.user_id}`
      const fullName = `${referrer.medunited_user.first_name} ${referrer.medunited_user.last_name}`;
      referrerData.push({
        fullName: fullName,
        ReferrerNumber:ReferrerNumber,
      });
    }
  }
  
  
    const constructAppliedFilterString = () => {
      const newAppliedFilters: string[] = [];
      /* rendering referrerName */
      if (selectedReferrerValue !== "") {
        const matchingReferrer = referrerData.find(entry => entry.ReferrerNumber === selectedReferrerValue);
        if (matchingReferrer) {
          const fullName = matchingReferrer.fullName;
          newAppliedFilters.push(`Referrer: ${fullName}`);
          }
      }      
    
      if (filterPatientName !== "") {
        newAppliedFilters.push(`Patient Name: ${filterPatientName}`);
      }
    
      if (filterFromDateVal && filterToDateVal) {
        newAppliedFilters.push(`Date Range: ${setDateFormat(filterFromDateVal.toString())} - ${setDateFormat(filterToDateVal.toString())}`);
      }
    
      // Add more filter criteria as needed
    
      setAppliedFilters(newAppliedFilters);
    };
    
    
  
    const handleFilterSubmit = async () => {
      let filterSubmitPayload: Payload | {} = {};
  
      try {
        if (selectedReferrerValue !== '' || filterPatientName !== '' || (filterFromDateVal && filterToDateVal)) {
          let intent = '';
          let params: { [key: string]: string | number | Date } = {};
  
          if(selectedReferrerValue !== '' && filterPatientName !== '' && filterFromDateVal && filterToDateVal){
            intent = 
              /* For admitted/arrived , discharged/consulted */
              (activeTabVal === 1 || activeTabVal === 2) ? 
                'getPatientFromThopForDreBasedOnDreIdAndPatientStatusAndVisitTypeAndIsconvertedtoIPAndTradePartnerIdAndPatientNameAndStartDateAndEndDate' 
              /* For Referred, Onhold, Referral lost */
              : (activeTabVal === 0 || activeTabVal === 3 || activeTabVal === 4) ? 
                'getPatientFromReferredPatientForDreBasedOnDreIdAndPatientStatusAndTradePartnerIdAndPatientNameAndDate' 
              : '';
  
              params = { ...params, tradePartnerId: selectedReferrerId, patientName: filterPatientName, startDate: filterFromDateVal, endDate: filterToDateVal };
          } else {
            /* When Referrer Name and Date Filter is applied  */
            if(selectedReferrerValue !== '' && (filterFromDateVal && filterToDateVal)){
              intent = 
              (activeTabVal === 1 || activeTabVal === 2) ? 
                'getPatientFromThopForDreBasedOnDreIdAndPatientStatusAndVisitTypeAndIsconvertedtoIPAndTradePartnerIdAndStartDateAndEndDate'
              : (activeTabVal === 0 || activeTabVal === 3 || activeTabVal === 4) ? 
                'getPatientFromReferredPatientForDreBasedOnDreIdAndPatientStatusAndTradePartnerIdAndDate'
              : '';
  
              params = { ...params, tradePartnerId: selectedReferrerId, startDate: filterFromDateVal, endDate: filterToDateVal };
            } else if(filterPatientName !== '' && (filterFromDateVal && filterToDateVal)){
              /* For Patient Name and Date Filter  */
              intent = 
              (activeTabVal === 1 || activeTabVal === 2) ? 
                'getPatientFromThopForDreBasedOnDreIdAndPatientStatusAndVisitTypeAndIsconvertedtoIPAndPatientNameAndStartDateAndEndDate'
              : (activeTabVal === 0 || activeTabVal === 3 || activeTabVal === 4) ? 
                'getPatientFromReferredPatientForDreBasedOnDreIdAndPatientStatusAndPatientNameAndDate'
              : '';
  
              params = { ...params, patientName: filterPatientName, startDate: filterFromDateVal, endDate: filterToDateVal };
            } else if(selectedReferrerValue !== '' && filterPatientName !== ''){
              /* if given only both patient name and referrer */
              intent = 
              (activeTabVal === 1 || activeTabVal === 2) ? 
                'getPatientFromThopForDreBasedOnDreIdAndPatientStatusAndVisitTypeAndIsconvertedtoIPAndTradePartnerIdAndPatientName'
              : (activeTabVal === 0 || activeTabVal === 3 || activeTabVal === 4) ?
                'getPatientFromReferredPatientForDreBasedOnDreIdAndPatientStatusAndTradePartnerIdAndPatientName'
              : '';
  
              params = { ...params, tradePartnerId: selectedReferrerId, patientName: filterPatientName };
            } else {
              /* When only referrer is selected */
              if (selectedReferrerValue !== '') {
                intent = 
                (activeTabVal === 1 || activeTabVal === 2) ? 
                  'getPatientFromThopForDreBasedOnDreIdAndPatientStatusAndVisitTypeAndIsconvertedtoIPAndTradePartnerId'
                : (activeTabVal === 0 || activeTabVal === 3 || activeTabVal === 4) ?
                  'getPatientFromReferredPatientForDreBasedOnDreIdAndPatientStatusAndTradePartnerId'
                : '';
  
                params = { ...params, tradePartnerId: selectedReferrerId };
              } else if (filterPatientName !== '') {
                /* When only Patient name is selected */
                intent = 
                  (activeTabVal === 1 || activeTabVal === 2) ? 
                    'getPatientFromThopForDreBasedOnDreIdAndPatientStatusAndVisitTypeAndIsconvertedtoIPAndPatientName'
                  : (activeTabVal === 0 || activeTabVal === 3 || activeTabVal === 4) ?
                    'getPatientFromReferredPatientForDreBasedOnDreIdAndPatientStatusAndPatientName'
                  : '';
  
                params = { ...params, patientName: filterPatientName };
              } else if ((selectedReferrerValue == '' && filterPatientName == '') && (filterFromDateVal && filterToDateVal)) {
                /* When only From & To Date are selected */
                intent = 
                  (activeTabVal === 1 || activeTabVal === 2) ? 
                    'getPatientFromThopForDreBasedOnDreIdAndPatientStatusAndVisitTypeAndIsconvertedtoIPAndStartDateAndEndDate'
                  : (activeTabVal === 0 || activeTabVal === 3 || activeTabVal === 4) ?
                    'getPatientFromReferredPatientForDreBasedOnDreIdAndPatientStatusAndDate'
                  : '';
                  
                params = { ...params, startDate: filterFromDateVal, endDate: filterToDateVal };
              }
            }
          }
          filterSubmitPayload = generatePayload(intent, params);
        } else {
          setMessage('Please Enter atleast one input to apply filter');
          return false;
        }
        
  
        const filterValuesResp = await _axios.post(
          `${process.env.REACT_APP_MAIN_SERVER_URL}${process.env.REACT_APP_PROJECT_ID}`,
          filterSubmitPayload,
          {
            headers: {
              version: VERSION,
            },
          }
        );
  
        if (filterValuesResp.status === 200) {
          setFilterApplied(true);
          setFilteredData(filterValuesResp.data);
          constructAppliedFilterString();
          handleClose();
        } else {
          // Handle error if needed
          setFilterApplied(false);
          setFilteredData([]);
          setMessage('No results for applied filter. Reason : ');
        }
      } catch (e: any) {
        console.log(e);
      }
  
    };  
    
    return (
      <div className={`filter_options_container`}>
        <div className="filter_referrer_block">
          <FormControl sx={{ m: 1, minWidth: 200, marginLeft: 0 }} size="small">
            <InputLabel id="demo-select-small-label">Referrer</InputLabel>
            <Select
              value={selectedReferrerValue}
              onChange={handleReferrerChange}
            >
              <MenuItem value="">
                <em>Select Referrers</em>
              </MenuItem>
              {    referrerList.map((referrer:any) => (
                <MenuItem
                key={referrer.medunited_user.user_id}
                value={referrer.medunited_user.user_id}
                >
                  {referrer.medunited_user.first_name + " " + referrer.medunited_user.last_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
  
        <div className="filter_patient_block">
          <TextField
              autoFocus
              margin="dense"
              label="Enter Patient Name"
              type="text"
              variant="standard"
              value={filterPatientName}
              onChange={handleFilterPatientNameChange}
            />
        </div>
  
        <div className="filter_status_block">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="From Date"
                value={filterFromDateVal}
                disableFuture
                inputFormat="dd/MM/yyyy"
                onChange={(newValue) => {
                  setFilterFromDate(newValue);
                } }
              renderInput={(params) => <TextField sx={{ m: 1, width: 200 }} size="small" {...params} />} />
          </LocalizationProvider>
        </div>
  
        <div className="filter_status_block">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="To Date"
                value={filterToDateVal}
                disableFuture
                inputFormat="dd/MM/yyyy"
                onChange={(newValue) => {
                  setFilterToDate(newValue);
                } }
              renderInput={(params) => <TextField sx={{ m: 1, width: 200 }} size="small" {...params} />} />
          </LocalizationProvider>
        </div>
  
        <div className="filter_status_buttons_block">
          <Button variant="outlined" onClick={handleFilterSubmit} style={{marginRight: "10px"}}>Submit</Button>
          <Button variant="outlined" onClick={handleClose}>Cancel</Button>
        </div>
      </div>
    );
  };
   export default RenderMenuItems;